// React Libraries
import React, {useState, useEffect} from "react";
import {Icon} from "semantic-ui-react";
import Dropdown from "react-bootstrap/Dropdown";

// Image
import AMBER_STRICT_LOGO from "../../assets/img/amber-strict-img-dark.png";

// Services
import * as apiConnection from "../../services/apiConnection";
import keycloak from "../../index";

const TLPDropdown = (props) => {

  const [tlpColorSelected, setTlpColorSelected] = useState("GREEN");
  const [tlpHexSelected, setTlpHexSelected] = useState("#669E35");

  const [TLPData, setTLPData] = useState([]);

  const header = {'Authorization': 'Bearer ' + keycloak.token};

  const handleTLPClick = (colorSelected) => {
    colorSelected = colorSelected.toUpperCase();
    setTlpColorSelected(colorSelected);
    props.selectedTLP(colorSelected);
    let TLPObject = TLPData.find(colorItem => colorItem.traffic_light_protocol_cd.toUpperCase() === colorSelected);
    setTlpHexSelected(getColor(TLPObject));
  };

  useEffect(() => {
    getTLPData();
  }, [])

  // retrieve static info on TLP, i.e. color rgbs, description, and name
  const getTLPData = () => {
    fetch(apiConnection.getTLPData(), {
      method: "GET",
      headers: {...header, "Content-Type": "application/json"},
      credentials: "same-origin"
    })
      .then(res => res.json())
      .then(resJson => {
        if(!resJson || resJson === undefined) {return}
        if(resJson && resJson.results && resJson.results.length > 0) {
          // order from least to most restricted TLP
          let orderedTLP = resJson.results.sort((a,b) => a.tlp_order - b.tlp_order);
          setTLPData(orderedTLP);
      }
    })
    .catch(err => console.error("Error in retrieving TLP data ", err));
  }

  let getColor = (cd) => {
    let color = "rgb(" + cd.color_rgb_red + "," + cd.color_rgb_green + "," + cd.color_rgb_blue + ")";
    return color;
  }

  return (
    <Dropdown
      className={props.quickSubmit ? "tlp-quick-submit tlp-dropdown-menu" : "tlp-dropdown-menu tlp-dropdown"} 
    >
      <Dropdown.Toggle variant="" id="tlp-dropdown-basic">
        {tlpColorSelected !== "AMBER+STRICT" 
          ?
            <>
              <Icon
                style={{color: tlpHexSelected}}
                className="tlp-color-icon-selector"
                name="circle"
                size="large"
              />
              <span className="tlp-color-title">TLP: {tlpColorSelected}</span>
            </>
          :
            <>
              <img src={AMBER_STRICT_LOGO}/>
              <span className="tlp-color-title-amber-strict">TLP: {tlpColorSelected}</span>
            </>
        }
        <Icon
            name="caret down"
            className={props.quickSubmit ? "tlp-arrow-icon tlp-arrow-icon-quickSubmit" : "tlp-arrow-icon"}
            size="small"
          />
      </Dropdown.Toggle>

      <Dropdown.Menu 
      // popperConfig={{strategy: "fixed"}}
      >
        {TLPData.map((tlp, index) => {
          if(tlp.traffic_light_protocol_cd !== "Amber+Strict") {
            return (
              <div key={index}>
                <Dropdown.Item
                  className="tlp-dropdown-item"
                  onClick={() => handleTLPClick(tlp.traffic_light_protocol_cd)}
                >
                  <Icon
                    style={{color: getColor(tlp)}}
                    className="tlp-color-icon-selector"
                    size="large"
                    name="circle"
                  />
                  <span className="tlp-color-title">{tlp.traffic_light_protocol_cd.toUpperCase()}</span>
                  <div className="tlp-color-desc">{tlp.description}</div>
                </Dropdown.Item>
                {index !== TLPData.length - 1 && <Dropdown.Divider />}
              </div>
            );
          } else {
            return (
              <div key={index}>
                <Dropdown.Item
                  className="tlp-dropdown-item"
                  onClick={() => handleTLPClick(tlp.traffic_light_protocol_cd)}
                >
                  <div>
                    <img src={AMBER_STRICT_LOGO} />
                    <span className="tlp-color-title-amber-strict">{tlp.traffic_light_protocol_cd.toUpperCase()}</span>
                  </div>
                  <div className="tlp-color-desc">{tlp.description}</div>
                </Dropdown.Item>
                {index !== TLPData.length - 1 && <Dropdown.Divider />}
              </div>
            );
          }
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default TLPDropdown;
