import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import React, { useEffect, useState } from "react";
import { getComparator, SortableTableHead, stableSort } from '../SubmissionList/utils/SortableTableHead';
import { rowsPerPageOptions } from '../SubmissionList/utils/submissionListUtils';
import { unSortableFields, SubmissionTableHeaderList as headerList } from '../SubmissionList/utils/SubmissionTableHeaderList';
import SearchReportRow from './SearchReportRow';

const useStyles = makeStyles({
    root: { width: '100%' },
    container: { maxHeight: 1000 }
});

const topPaginationStyle = makeStyles({
    root: { color: 'white' },
    selectIcon: { color: 'white' }
});

export default function NewSearchReport(props) {
    const classes = useStyles();
    const topPaginationClass = topPaginationStyle();
    const { queryResults } = props;
    const rows = queryResults;
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState(headerList[0].id);
    const [sortedRows, setSortedRows] = useState(rows);
    const [pageChanged, setPageChanged] = useState(false);
    const [sortStatusClicked, setSortStatusClicked] = useState(false);

    const onPageChange = () => {
        setPageChanged(false);
    }

    const handleRequestSort = (event, fieldToOrder) => {
        if(order==='desc') {
            setSortStatusClicked(true);
          } else {
            setSortStatusClicked(false);
        }

        if (unSortableFields.indexOf(fieldToOrder) !== -1) return;
        const isAsc = orderBy === fieldToOrder && order === 'asc';
        const orderValue = isAsc ? 'desc' : 'asc';
        setOrder(orderValue); // change to opposite order when user clicks
        setOrderBy(fieldToOrder);
        setPage(0);
        setSortedRows(stableSort(rows, getComparator(orderValue, fieldToOrder)));
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setPageChanged(true);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        setPage(0);
    };

    useEffect(() => {
        setPage(0);
        setRowsPerPage(10);
        setSortedRows(rows);
    }, [rows]);

    return (
        <div className="body-container">
            <div className="home-header">
                <table width="100%">
                    <tbody>
                        <tr>
                            <td><h4>Results</h4></td>
                            <td style={{ textAlign: "right" }}>
                                <TablePagination
                                    rowsPerPageOptions={rowsPerPageOptions}
                                    component="div"
                                    count={rows.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    classes={topPaginationClass}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <TableContainer className={classes.container}>
                <Table stickyHeader size='small' aria-label="sticky table">
                    <SortableTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        headCells={headerList}
                    />
                    <TableBody>
                        {sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                            <SearchReportRow 
                                key={index} 
                                row={row} 
                                sortStatusClicked={sortStatusClicked}
                                // sortSubmissionCountClicked={sortSubmissionCountClicked}
                                pageChanged={pageChanged} 
                                onPageChange={onPageChange}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </div>
    );
}