import '../../assets/css/basicSearchBox.css'
import React from "react";
import {withRouter} from 'react-router';

const NoResult = (props) => {

    const goToHome = () => {
        if (props.history) {
            props.history.push("/home");
        }
    }

    return (
        <div>
            <div className="no_search_result_found">
                <h1
                style={{textAlign: 'center'
                    }}
                > No Results Found</h1>
            </div>
            <div style={{textAlign: "center"}}>
                <button className='no_search_result_button'
                    onClick={goToHome} 
                >
                    OK
                </button>
            </div>  
        </div>
    )
}

export default withRouter ( NoResult );