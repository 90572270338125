//React
import React, {Component} from "react";
// eslint-disable-next-line
import {Link} from "react-router-dom";
import confirmationCheckImg from "./../../assets/img/confirmation-check.png";

class Confirmation extends Component {
  state = {};

  componentDidMount() {
    //this.props.location.state.details.restht;
    //  console.log(" thank you info  " + this.props.location.state.sidDetails);
  }

  render() {
    return (
      <div className="confirmation-container text-center">
        <h1> Thank you for your Submission. </h1>

        <img
          className="confirmation-img green-confirm"
          src={confirmationCheckImg}
          alt="Successful submission confirmation"
        />
        <br></br>

        <Link className="confirmation-link" to="/submissionList">
          Submissions |
        </Link>

        <Link className="confirmation-link" to="/submission">
          Submit Sample
        </Link>
      </div>
    );
  }
}

export default Confirmation;
