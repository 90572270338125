import { TableCell, TableSortLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';


const useStyles = makeStyles({
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    }
});

function descendingComparator(a, b, orderBy) {
    let y = "";
    let x = "";

    if (orderBy === 'created_ts') {
        return (new Date(b[orderBy])).getTime() > (new Date(a[orderBy])).getTime() ? 1 : -1;
    } 

    else if (orderBy === "traffic_light_protocol_cd") {
        y = b[orderBy].traffic_light_protocol_cd;
        x = a[orderBy].traffic_light_protocol_cd;

        let descendingTLPOrder = ["Clear", "Green", "Amber", "Amber+Strict", "Red"];

        let indexOfMatchingY = descendingTLPOrder.findIndex(e=>e===y);
        let indexOfMatchingX = descendingTLPOrder.findIndex(e=>e===x);

        return indexOfMatchingY > indexOfMatchingX ? 1 : -1;
    }
    
    else if (orderBy !== 'file_cnt') {
        if(b[orderBy]) {
            if(typeof b[orderBy] === 'string') {
                y = b[orderBy].toLowerCase();
            } else {
                y = b[orderBy];
            }
        } 

        if(a[orderBy]) {
            if(typeof a[orderBy] === 'string') {
                x = a[orderBy].toLowerCase();
            } else {
                x = a[orderBy];
            }
        } 

        // let y = b[orderBy] ? b[orderBy].toLowerCase() : '';
        // let x = a[orderBy] ? a[orderBy].toLowerCase() : '';
        return y > x ? 1 : -1;
    }

    // this is for sorting Submission Count (file_cnt)
    // we decided to disable the ability to sort by Submission Count, until further notice by customer
    else {
        if(b[orderBy]) {
            if(typeof b[orderBy] === 'string') {
                y = b[orderBy];
            } else {
                y = b[orderBy];
            }
        } 

        if(a[orderBy]) {
            if(typeof a[orderBy] === 'string') {
                x = a[orderBy];
            } else {
                x = a[orderBy];
            }
        } 

        // let y = b[orderBy] ? b[orderBy] : '';
        // let x = a[orderBy] ? a[orderBy] : '';
        return y > x ? 1 : -1;
    }
  }
  
export function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  
export function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

export function SortableTableHead(props) {
    const { order, orderBy, onRequestSort, headCells, noFirstBlankColumn } = props;
    const classes = useStyles();

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    }

    return (
        <TableHead>
            <TableRow>
                { !noFirstBlankColumn &&
                    <TableCell style={{width: "10px", backgroundColor: "#D3D3D3"}} />
                }
                {headCells.map((headCell) => 
                    {
                    if(headCell.id !== "file_cnt") {
                        return (
                            <TableCell
                                key={headCell.id}
                                sortDirection={orderBy === headCell.id ? order : false}
                                style={{ fontWeight: "bold", backgroundColor: "#D3D3D3", width: headCell.width }}
                            >
                                <TableSortLabel
                                    active={orderBy === headCell.id}
                                    direction={orderBy === headCell.id ? order : 'asc'}
                                    onClick={createSortHandler(headCell.id)}
                                >
                                    {headCell.label}
                                    {orderBy === headCell.id ? (
                                        <span className={classes.visuallyHidden}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                    ) : null}
                                </TableSortLabel>
                            </TableCell>
                        )
                    } else if(headCell.id === "file_cnt") {
                        return (
                            <TableCell
                                key={headCell.id}
                                // sortDirection={orderBy === headCell.id ? order : false}
                                style={{ fontWeight: "bold", backgroundColor: "#D3D3D3", width: headCell.width }}
                            >
                                    {headCell.label}
                            </TableCell>
                        )
                    }
                })}
            </TableRow>
        </TableHead>
    );
}