import React, { Fragment } from "react";

const Spinner = () => {
  return (
    <Fragment>
      <div className="text-center">
        <div
          className="spinner-border"
          style={{
            width: "15rem",
            height: "15rem",
            position: "relative",
            top: "10rem",
          }}
          role="status"
        >
          <span className="sr-only">Loading...</span>
        </div>
        <h3>
          {" "}
          Loading.
          <div className="spinner-grow spinner-grow-sm" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          <div className="spinner-grow spinner-grow-sm" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </h3>
      </div>
    </Fragment>
  );
};

export default Spinner;
