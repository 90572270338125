//React
import React, {Component} from "react";
// eslint-disable-next-line
import {Link} from "react-router-dom";
import confirmationDenyImg from "./../../assets/img/confirmation-deny.png";

class DenyUrl extends Component {
  state = {};
  render() {
    return (
      <div className="confirmation-container text-center">
        <h1> Submission Failed </h1>
        <img
          className="confirmation-img"
          src={confirmationDenyImg}
          alt="Successful submission confirmation"
        />
        <br></br>
        <Link className="confirmation-link" to="/submission">
          Invalid URL.
        </Link>
      </div>
    );
  }
}

export default DenyUrl;
