import React, {useState} from "react";

import {Form, Row, InputGroup} from "react-bootstrap";

import {useForm} from "react-hook-form";
import {withRouter} from 'react-router';
import {connect} from "react-redux";

import {Icon} from "semantic-ui-react";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import {ClickAwayListener} from "@mui/base/ClickAwayListener";

import {ReactDatePicker} from '../utils/Calendar';

import * as yaraWarningTools from '../yara/utils/yaraWarningTools';
import * as yaraActionTypes from '../../redux/actions/yaraActions';
import {YARA_DISPLAY_MODES} from '../yara/utils/YaraDisplayModes';
import {YaraChangesLostAlert} from "../yara/edit/YaraChangesLostAlert";


/**
 * MAIN FUNCTION COMPONENT
 */
const BasicSearchBox = (props) => {
    const {register, handleSubmit} = useForm({mode: "onChange"});

    const [showChangesLostAlert, setShowChangesLostAlert] = useState(false);
    const [ruleName, setRuleName] = useState("");
    const [ruleId, setRuleId] = useState("");
    const [stateObj, setStateObj] = useState({});
    const [selectedDates, setSelectedDates] = useState({});
    
    const [showCalendar, setShowCalendar] = useState(false);
    const [calendarRangeWarning, setCalendarRangeWarning] = useState(false);
    const [calendarNotSelectedWarning, setCalendarNotSelectedWarning] = useState(false);

    const [submitButtonClicked, setSubmitButtonClicked] = useState(false);
    const [clearButtonClicked, setClearButtonClicked] = useState(false);

    const [isDatePresent, setIsDatePresent] = useState(false);
    const [searchTextValue, setSearchTextValue] = useState("");
    const [searchEntireDatabase, setSearchEntireDatabase] = useState(false);

    const handleClickClearButton = () => {
        if(searchTextValue || calendarNotSelectedWarning || isDatePresent) {
            clearSearch();
        }
    }

    const handleSearchText = (e) => {
        setSearchTextValue(e.target.value);
//        if(e.target.value && !selectedDates.start_date) {
//            setCalendarNotSelectedWarning(true);
//        }
    }

    const handleClickAway = () => {
        setShowCalendar(false);
    }

    const onSubmit = (data, e) => {
        e.preventDefault();
        selectedDates["search_text"] = searchTextValue;

    //    // text entered, but no date selected
    //    if(searchTextValue && !selectedDates.start_date) {
    //        setCalendarNotSelectedWarning(true);
    //    }

        // when no text, no date, or range is exceeded, nothing happens when submit clicked
        if (!searchTextValue  && submitButtonClicked) {
            setSubmitButtonClicked(false);
        }

        // open search
        else if (searchTextValue  && submitButtonClicked && !calendarRangeWarning) { 
            openSearch(selectedDates);
            clearSearch();
            e.target[0].value='';
        }

        // clear search
        else if (clearButtonClicked) {
            clearSearch();
            e.target[0].value='';
        }
    }
    
    const openSearch = (text) => {
        delete text["date_selected"];
        const loc = props.location.pathname;

        if (loc == "/analystTools/yara") {
            setStateObj( {searchText: text} );
            checkIfYaraRuleIsLocked({searchText: text});
        } else {
            if (props.history) {
            //props.history.push("/search", {searchText: text, searchEntireDatabase: searchEntireDatabase});
            props.history.push("/search", {searchText: 
                {
                    start_date: text.start_date,
                    end_date: text.end_date,
                    search_text: text.search_text,
                    searchEntireDatabase: searchEntireDatabase
                }
            });
            }
        }
    }

    const clearSearch = () => {
        // e.target[0].value='';
        selectedDates.show_dates='';
        selectedDates.start_date ='';
        selectedDates.end_date='';
        setIsDatePresent(false);
        setCalendarNotSelectedWarning(false);
        setCalendarRangeWarning(false);
        setClearButtonClicked(false);
        setSubmitButtonClicked(false);
        setSearchTextValue("");
        setShowCalendar(false);
        setSearchEntireDatabase(false);
    }

    const checkIfYaraRuleIsLocked = (state) => {
        if(props.hasRuleBeenEdited) {
            yaraWarningTools.getLockedYaraRule().then( 
                isAYaraRuleLocked => {
                    if(isAYaraRuleLocked !== undefined) {
                        setRuleName(isAYaraRuleLocked.rule_name);
                        setRuleId(isAYaraRuleLocked.yara_rule_adhoc_id);
                        setShowChangesLostAlert(true);
                    } else {
                        goToBasicSearchResults(state);
                    }
                } 
            );
        } else {
            goToBasicSearchResults(state);
        }
    }

    const goToBasicSearchResults = (state) => {
        if (props.history) {
            props.history.push("/search", state);
        }
    }

    const moveToNewItem = () => {
        setShowChangesLostAlert(false);
        yaraWarningTools.unlockYaraRule(ruleId);
        props.closeDisplayMode({});
        if (props.history) {
            props.history.push("/search", stateObj);
        }
    }

    return (
        <div>
            <Form className="quick-form nav-search-form" 
                onSubmit={handleSubmit(onSubmit)}
                style={{width: "600px"}}
                >
                <Row id="nav-search-row">

                    <Form.Group style={{marginBottom: "0"}}>
                        <InputGroup style={{flexWrap: "nowrap"}}>
                            <Form.Control 
                                onChange={e => handleSearchText(e)}
                                type='text' 
                                className="nav-search-bar"
                                style={{borderBottomLeftRadius: (isDatePresent || calendarRangeWarning || calendarNotSelectedWarning) ? "0" : ".15rem"}}
                                placeholder="Search..."
                                name="search_text"
                                value={searchTextValue} 
                            >
                            </Form.Control>


                            {/* clear icon */}

                            <div className="nav-search-clear">
                                <IconButton
                                    onClick={(e) => handleClickClearButton(e)} 
                                    aria-label="clear"
                                    aria-required="true"
                                    type="button"
                                    className="nav-search-clear-button"
                                    style={{visibility: (searchTextValue || isDatePresent || calendarRangeWarning) ? "visible" : "hidden"}}
                                >
                                    <ClearIcon className="nav-search-clear-icon" fontSize="small"/>
                                </IconButton>
                            </div>


                            {/* calendar icon */}

                            { !searchEntireDatabase &&
                                <InputGroup.Prepend>
                                    <InputGroup.Text
                                        className="nav-search-calendar-button" 
                                        style={{
                                            borderTopRightRadius: (searchTextValue && !calendarRangeWarning) ? "0" : ".25rem",
                                            borderBottomRightRadius: (searchTextValue && !calendarRangeWarning) ? "0" : ".25rem",
                                            background: showCalendar ? "#e1e4df" : "#e9ece7"
                                        }}
                                    >
                                    <Icon 
                                        size='large' 
                                        name='calendar alternate outline'
                                        onClick={() => setShowCalendar(true)} 
                                    />
                                    </InputGroup.Text>
                                </InputGroup.Prepend>
                            }


                            {/* search icon */}

                            <div 
                                style={{
                                    backgroundColor: (searchTextValue && !calendarRangeWarning) ? "white" : "#003666", 
                                    borderTop: (searchTextValue && !calendarRangeWarning) ? "1px solid #ced4da" : "none", 
                                    borderBottom: (searchTextValue && !calendarRangeWarning) ? "1px solid #ced4da" : "none",
                                    borderRight: (searchTextValue && !calendarRangeWarning) ? "1px solid #ced4da" : "none",
                                }}
                                className="nav-search-submit"
                            >
                                <IconButton
                                    onClick={() => setSubmitButtonClicked(true)} 
                                    aria-label="clear"
                                    aria-required="true"
                                    type="submit"
                                    className="nav-search-submit-button"
                                    style={{visibility: (searchTextValue && !calendarRangeWarning) ? "visible" : "hidden"}}
                                >
                                     <SearchIcon />
                                </IconButton>
                            </div>

                        </InputGroup>
                    </Form.Group>


                    {/* date range */}

                    {isDatePresent && 
                        <div className="selected-date-range">
                            {JSON.stringify(selectedDates.show_dates).slice(1).slice(0, -1)}
                        </div>
                    }


                    {/* warning - date range exceeded */}

                    {calendarRangeWarning &&
                        <div className="selected-date-range">
                            Date range cannot exceed 90 days.
                        </div>
                    }


                    {/* message - default range */}

                    {!isDatePresent && 
                        <div className="default-date-range">
                            <input
                                name={'Search entire DB related aria-label for name'}
                                onChange={(e) => (e.target.checked ? setSearchEntireDatabase(true) : setSearchEntireDatabase(false))}
                                id="searchEntireDatabaseId"
                                checked={searchEntireDatabase}
                                type="checkbox"
                                style={{marginRight: "5px"}}
                            />
                            Search all records? Default date range is the last 30 days.
                        </div> 
                    }

                    {/* warning - date not selected 

                        {calendarNotSelectedWarning && !isDatePresent && !calendarRangeWarning && searchTextValue &&
                            <div className="selected-date-range">
                                Select a date range
                            </div>
                        }
                    */}

                    {showCalendar && 
                        <ClickAwayListener onClickAway={handleClickAway}>
                            <div>
                                <ReactDatePicker 
                                    setSelectedDates={setSelectedDates} 
                                    selectedDates={selectedDates} 
                                    showCalendar={showCalendar}
                                    setShowCalendar={setShowCalendar}
                                    calendarRangeWarning={calendarRangeWarning}
                                    setCalendarRangeWarning={setCalendarRangeWarning}
                                    setDate={() => setIsDatePresent(true)}
                                />
                            </div>
                        </ClickAwayListener>
                    }
                    
                </Row>                        
            </Form>
            {showChangesLostAlert &&
                <YaraChangesLostAlert
                    showModal={showChangesLostAlert}
                    closeThisAlert={() => setShowChangesLostAlert(false)}
                    moveToNewItem={moveToNewItem}
                    firstYaraRuleName={ruleName} 
                />
            } 
        </div>
        )
    }

const mapStateToProps = state => {
    return {
        displayMode: state.yaraReducer.displayMode
    }
  }

const mapDispatchToProps = dispatch => {
    return {
        closeDisplayMode: () => dispatch({type: yaraActionTypes.CLOSE_DISPLAY, mode: YARA_DISPLAY_MODES.NONE}),
        unsetDisplayMode: (yaraObj) => dispatch({ type: yaraActionTypes.SET_DISPLAY_MODE, mode: YARA_DISPLAY_MODES.NONE, yara: yaraObj }),
    }
  }
  
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BasicSearchBox));
