import React, { Component } from 'react';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import { TableCell, TableHead } from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { RawReportModal } from "./RawReportModal";
import * as apiConnection from "../../../../services/apiConnection";
import "../../../../assets/css/FileAnalysisReport/RawAnalysisTab.css";
import { STATUS } from "../../../../utils/status";
import { connect } from "react-redux";
import keycloak from "../../../../index";
import * as httpCalls from "../../../../services/httpCalls";
import Button from "react-bootstrap/Button";
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';

class RawAnalysisTab extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            yaraDownloadContent: "",
            vmrayScreenshotsExist: false,
            displayedReportName: '',
            displayedReportContent: '',
            listOfEmptyReports: [],
            reportTypeCount: 0,
            reportAllRawAnalysisList: [],
            downList: []
        }

        this.reportTypeList = ["NSRL", "PEFILE", "STATIC", "METADEFENDER", "YARA", "STIX21", "VMRAY"];

        this.openReport = this.openReport.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.isResultEmpty = this.isResultEmpty.bind(this);
        this.reportType = null;
        this.reportUrl = null;
        this.reportName = null;
        this.metadefenderIntDate = null;
        this.headers = {
            'Authorization': 'Bearer ' + keycloak.token,
            "Content-Type": "application/json"
        };
    }

    handleCloseModal() {
        this.setState({ showModal: false })
    }

    openReport(tid, type) {
        let input = {
            tid: tid,
            type: type
        }
        fetch(apiConnection.viewReportUrl(), {
                method: "PUT",
                headers: {...this.headers},
                credentials: "same-origin",
                body: JSON.stringify(input)
        })
            .then(response => response.json())
            .then(res => {
                const content = this.processResponseData(type, res);
                this.setState({ showModal: true, displayedReportName: type, displayedReportContent: content });
            })
            .catch(err => console.log("Fetch Submission : " + err));
    }

    componentDidMount() {
        const tid = this.props.currentTid;
        const metaData = this.props.reportMetaDataForTid;
        if (metaData != undefined) {
            this.reportTypeList.map(type => {
                this.isResultEmpty(tid, type, metaData);
            })
        }
        this.checkForYARARules();
        this.checkForVMRayScreenshots();
    }

    componentDidUpdate(prevProps) {
        if(this.props.currentTid !== prevProps.currentTid) {
            console.log("RA Tab, this.props.currentTId:", this.props.currentTid);
            console.log("RA Tab, reportMetaDataForTid: ", this.props.reportMetaDataForTid, Object.keys(this.props.reportMetaDataForTid).length)
        }
    }

    isResultEmpty(tid, type, metaData) {
        const isCompleted = metaData[type] && (metaData[type].status.toLowerCase() == STATUS.COMPLETED.toLowerCase());
        
        let input = {
            tid: tid,
            type: type
        }

        fetch(apiConnection.viewReportUrl(), {
            method: "PUT",
            headers: { ...this.headers },
            credentials: "same-origin",
            body: JSON.stringify(input)
        })
            .then(response => response.json())
            .then(res => {
                if (res) {
                    const content = this.processResponseData(type, res);
                    if (isCompleted) {
                        if (!content || content === undefined || (isCompleted && content.length === 0) || Object.keys(content).length === 0) {
                            this.setState(prevState => ({
                                listOfEmptyReports: [...prevState.listOfEmptyReports, type]
                            }));
                        }
                    }
                        //if only key in YARA is yara_version, do not display
                    if (type == "YARA" && "yara_version" in content && Object.keys(content).length === 1) {
                        this.setState(prevState => ({
                            listOfEmptyReports: [...prevState.listOfEmptyReports, type]
                        }));
                    }
                    //after all reports have been checked for emptiness, then render non-empty reports on UI
                    this.setState(prevState => ({
                        reportTypeCount: prevState.reportTypeCount + 1
                    }));
                } else { return };
            })
        .catch(err => console.log("Fetch Submission: " + err));
    }

    processResponseData(type, res) {
        switch (type) {
            case 'STATIC':
                return res.results[0];
            case 'METADEFENDER':
                return res.results[0];
            case 'STIX21': 
                return res.results;
            case 'NSRL':
                let hash = res.results[0].hash_info;
                return (hash && Array.isArray(hash) && hash.length > 0)
                    ? hash
                    : res.messages;
            case 'PEFILE':
                return res.results[0].results;
            case 'YARA':
                return res.results[0].results[0];
            case 'VMRAY': 
                if ( res.errors !== null) { 
                    return res;
                }  //if error continue to forward error message

                return res.results;
            default:
                return;
        }
    }
    handleDownloadCheckbox = (e, key, status, reportUrl, location, tid, reportType) => {
        const { checked } = e.target;
        const dateStartPosition = location.search(/\d/g);
        // added this line for prd:
        const submittedDate = this.props.TIDinfo.submittedDate.substr(0,10);
        const dateEndPosition = dateStartPosition + 10;
        let reportNameDate = location.slice(dateStartPosition, dateEndPosition);

        if (reportType == 'METADEFENDER') {
            this.metadefenderIntDate = reportNameDate;
        }
        if (reportType == 'STIX21') {
            // reportNameDate = 'submitDate-' + this.metadefenderIntDate;
            // added this line for prd:
            reportNameDate = 'submitDate-' + submittedDate;
        } else {
            reportNameDate = 'date-' + reportNameDate;
        }

        const reportName = 'tid-' + tid + '-' + reportNameDate + '-' + reportType + '.JSON';
        const downloadListData = {
            reportUrl: reportUrl,
            reportFileName: reportName,
            reportType: reportType,
            status: status,
            key: key
        }
        if (checked) {
            document.getElementById(key).checked = true;
            // this.state.downList.push(downloadListData);
            // this.setState({downList: this.state.downList});
            this.setState(prevState => ({
                downList: [...prevState.downList, downloadListData]
            }))
        } else {
            document.getElementById(key).checked = false;
            // console.log("Download List length before:  " + this.state.downList.length);
            // console.log("List key:  "+  key);
            for (let i = 0; i < this.state.downList.length; i++) {
                if (this.state.downList[i].key == key) {
                    this.state.downList.splice(i, 1);
                    // console.log("List position:  "+  i);
                }
            }
            if (this.state.downList.length == 0) {
            // this.setState({downList: this.state.downList});
            this.setState({ downList: [] });
            document.getElementById('allDownloadCheckbox').checked = false;
            } 
            // console.log("Download List length after:  " + this.state.downList.length);
            // console.log("List key:  "+  key)
            // console.log('>>>>>>>>>>>>');
        }
    };

    downloadVMRayScreenshots = () => {
        const url = apiConnection.downloadVMRayScreenshots();
        const header = { 
          "Content-Type": "application/json",
          'Authorization': 'Bearer ' + keycloak.token
        }
        const method = "PUT";
        const data_in = {tid: [this.props.currentTid]}
        
        httpCalls
          .configureAxiosRequestDownload(
            url,
            header,
            method,
            data_in
          )
          .then(res => {
            if (res.data) {
              const downloadUrl = window.URL.createObjectURL(new Blob([res.data], {type: "application/zip"}));
              const link = document.createElement('a');
              link.href = downloadUrl;
              const zipFileName = "VMRay_Screenshots_" + this.props.currentTid + '.zip';
              link.setAttribute('download', zipFileName);
              document.body.appendChild(link);
              link.click();
              link.remove();
            }
          })
          .catch(err => {
            console.error("Error with downloading VMRay screenshots: " + err);
          });
    }

    handleDownloadDetails (type) {
        this.checkForVMRayScreenshots();
        if(type==="YARA" && this.state.yaraDownloadContent) {
            return(
                <Button 
                    onClick={() => this.downloadYARARules()} 
                    className="download-details-btn"
                >
                    <DownloadRoundedIcon /> YARA Rules
                </Button>
            )
        }
        
        else if(type==="VMRAY") {
            return(
                <Button 
                    onClick={() => this.downloadVMRayScreenshots()} 
                    className="download-details-btn"
                >
                    <DownloadRoundedIcon /> Sandbox Executable
                </Button>
            )
        }
    }

    checkForVMRayScreenshots = () => {
        const input= {tid: [this.props.currentTid]}
    
        fetch(apiConnection.getVMRayScreenshots(), {
            method: "PUT",
            headers: { 
              "Content-Type": "application/json",
              'Authorization': 'Bearer ' + keycloak.token
            },
            body: JSON.stringify(input),
        })
        .then(res => res.json())
        .then(resJson => {
          if(resJson && (resJson.errors.length > 0 || resJson.messages.length > 0)){
            if(resJson.messages[0].includes("No screenshots exist for tid")) {
                this.setState({vmrayScreenshotsExist: false});
            }
          }else if(resJson.results) {
            this.setState({vmrayScreenshotsExist: true})
          }
        })
        .catch(error => {
            console.error("Error in getting VMRay screenshots: ", error);
        }); 
    }

    checkForYARARules () {
        let input = {
            tid: this.props.currentTid,
        }
        fetch(apiConnection.downloadYaraRuleMatches(), {
            method: "PUT",
            headers: {...this.headers},
            credentials: "same-origin",
            body: JSON.stringify(input)
        })
            .then(res => res.json())
            .then(res => {
                if(res.messages && res.messages[0] === "No YARA matches") {
                    this.setState({yaraDownloadContent: null});
                    return;
                }
                if(res.results && res.results.length > 0) {
                    this.setState({yaraDownloadContent: res.results[0]});
                }
            })
            .catch(err => console.log("Fetch Submission : " + err));
    }

    downloadYARARules() {
        var link = document.createElement('a');
        link.download = "YARA_Rules_" + this.props.currentTid + ".txt";
        var blob = new Blob([this.state.yaraDownloadContent], {type: 'text/plain'});
        link.href = window.URL.createObjectURL(blob);
        link.click();
    }
    
    selectAllDownloadCheckbox = (e) => {
        const { checked } = e.target;
        for (let i = 0; i < this.state.reportAllRawAnalysisList.length; i++) {
            const dateStartPosition = this.state.reportAllRawAnalysisList[i].location.search(/\d/g);
            const dateEndPosition = dateStartPosition + 10;
            let reportDate = this.state.reportAllRawAnalysisList[i].location.slice(dateStartPosition, dateEndPosition)
            if (this.state.reportAllRawAnalysisList[i].reportType == 'METADEFENDER') {
                this.metadefenderIntDate = reportDate
            }
            if (this.state.reportAllRawAnalysisList[i].reportType == 'STIX21') {
                reportDate = 'submitDate-' + this.metadefenderIntDate;
                // reportDate = 'date-' + this.metadefenderIntDate;
            } else {
                reportDate = 'date-' + reportDate;
            }
            const reportName = 'tid-' + this.state.reportAllRawAnalysisList[i].tid + '-' + reportDate + '-' + this.state.reportAllRawAnalysisList[i].reportType + '.JSON';
            this.state.reportAllRawAnalysisList[i].reportFileName = reportName;
            this.setState({ reportAllRawAnalysisList: this.state.reportAllRawAnalysisList });
        }

        this.setState({ downList: this.state.reportAllRawAnalysisList });
        for (let i = 0; i < this.state.reportAllRawAnalysisList.length; i++) {
            if (this.state.reportAllRawAnalysisList[i].status == 'Completed') {
                if (checked) {
                    // console.log('Select All Boxes:   ' + JSON.stringify(this.state.reportAllRawAnalysisList[i]))
                    // console.log("downList Length:   " + this.state.reportAllRawAnalysisList.length);
                    document.getElementById(this.state.reportAllRawAnalysisList[i].key).checked = true;
                } else {
                        document.getElementById(this.state.reportAllRawAnalysisList[i].key).checked = false;
                    this.setState({ downList: [] });
                    this.setState({ reportAllRawAnalysisList: [] });
                }
            }
        }
    }

    async downloadReport() {
        for (let i = 0; i < this.state.downList.length; i++) {
            if (this.state.downList[i].status == "Completed") {
                // console.log("Inside For Loop Download List:  " + JSON.stringify(this.state.downList[i]));
                let input = {
                    tid: this.props.currentTid,
                    type: this.state.downList[i].reportType
                }
                const response = await httpCalls
                    .configureAxiosRequest(this.state.downList[i].reportUrl, this.headers, "PUT", input)
                    .then((res) => {
                        if (res.data) {
                            const link = document.createElement('a');
                            const processedData = JSON.stringify(this.processResponseData(this.state.downList[i].reportType, res.data));
                            link.setAttribute("href", "data:text;charset=utf-8," + encodeURIComponent(processedData));
                            link.setAttribute('download', this.state.downList[i].reportFileName);
                            document.body.appendChild(link);
                            link.click();
                            link.remove();
                        }
                    })
                    .catch((err) => {
                        console.error("Download analysis report request - err: " + err);
                    });
                document.getElementById(this.state.downList[i].key).checked = false;
                document.getElementById('allDownloadCheckbox').checked = false;
            } 
        }
        this.setState({ downList: [] });
        // console.log("Outside For Loop Download List:  " + JSON.stringify(this.state.downList));
    }

    render() {
        const reportMetaDataForTid = this.props.reportMetaDataForTid;
        const tid = this.props.currentTid;

        // let completeReportTypeList = this.reportTypeList;
        // let stix21Completed = false;
        // if (this.state.reportTypeCount === this.reportTypeList.length && reportMetaDataForTid) {  
        //     for (const type in reportMetaDataForTid) {
        //         if (reportMetaDataForTid[type].status.toLowerCase() !== STATUS.COMPLETED.toLowerCase()) {
        //             stix21Completed = false;
        //             break;
        //             // console.log("reportMetaDataForTid[type].status:["+ type + "]:   ", reportMetaDataForTid[type].status);
        //         } else {
        //             stix21Completed = true;
        //             // console.log("reportMetaDataForTid[type].status:["+ type + "]:   ", reportMetaDataForTid[type].status);
        //         }
        //     }
        // };
        // if (stix21Completed){
        //     completeReportTypeList = this.reportTypeList.filter(type => !this.state.listOfEmptyReports.includes(type))
        // }

        return (
            <div id={'raw-analysis'}>
                <TableContainer>
                    <Table aria-label="raw analysis table" size='small' >
                        <TableHead >
                            <TableRow>
                                <TableCell>View Details</TableCell>
                                <TableCell>
                                    <input
                                        id={"allDownloadCheckbox"}
                                        name={"downloadBox"}
                                        className="form-checks"
                                        type="checkbox"
                                        onChange={(e) => this.selectAllDownloadCheckbox(e)}
                                        defaultChecked={false}
                                    />
                                    {this.state.downList.length > 0 ?
                                    <DownloadRoundedIcon onClick={() => this.downloadReport()}/>
                                    :
                                    <DownloadRoundedIcon />
                                    }
                                </TableCell>
                                <TableCell>Status</TableCell>
                                {
                                (this.state.yaraDownloadContent || this.state.vmrayScreenshotsExist)
                                &&
                                <TableCell>Download Details</TableCell>
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody width="100%">
                            {this.state.reportTypeCount === this.reportTypeList.length && reportMetaDataForTid &&
                            this.reportTypeList.filter(type => !this.state.listOfEmptyReports.includes(type))
                                .map((type, index) => {
                                    if (reportMetaDataForTid[type] && reportMetaDataForTid[type] !== null) {
                                        const status = reportMetaDataForTid[type].status;
                                        const location = reportMetaDataForTid[type].location;
                                        // console.log("type, status, location: ", type, status, location);

                                        const reportUrl = apiConnection.viewReportUrl(tid, type);
                                        const reportName = reportMetaDataForTid[type].file_name;
                                        const allRawAnalysis = {
                                            reportUrl: reportUrl,
                                            reportFileName: reportName,
                                            reportType: type,
                                            status: status,
                                            location: reportMetaDataForTid[type].location,
                                            tid: tid,
                                            key: index
                                        }
                            // {completeReportTypeList.map((type, index) => {
                            //     if (reportMetaDataForTid[type] && reportMetaDataForTid[type] !== null) {
                            //         let status = reportMetaDataForTid[type].status;
                            //         let i=0;
                            //         do {  
                            //             if (!stix21Completed && this.state.listOfEmptyReports[i] == type) 
                            //             {
                            //                 status = "In_Progress";
                            //             } 
                            //             i++;
                            //         } while ( i <= this.state.listOfEmptyReports.length );
                            //         const location = reportMetaDataForTid[type].location;
                            //         const reportUrl = apiConnection.viewReportUrl(tid, type);
                            //         const reportName = reportMetaDataForTid[type].file_name;
                            //         const allRawAnalysis = {
                            //             reportUrl: reportUrl,
                            //             reportFileName: reportName,
                            //             reportType: type,
                            //             status: status,
                            //             location: reportMetaDataForTid[type].location,
                            //             tid: tid,
                            //             key: index
                            //         }
                                    {
                                        if (this.state.reportAllRawAnalysisList.length <= index &&
                                            reportMetaDataForTid[type].status == "Completed"
                                            ) {
                                            this.state.reportAllRawAnalysisList.push(allRawAnalysis)
                                        }
                                    }
                                    return (
                                        <TableRow key={index}>
                                            {status.toLowerCase() !== STATUS.COMPLETED.toLowerCase() ?
                                                (<>
                                                    {type !== 'CUCKOO' ?
                                                        (<>
                                                            <TableCell className={'report-name-link'}>
                                                                <a href={"#"} onClick={() => this.openReport(tid, type)}>{type}</a>
                                                            </TableCell>
                                                        </>)
                                                        :
                                                        (<>
                                                            <TableCell> {type}</TableCell>
                                                        </>)
                                                    }
                                                    <TableCell>
                                                    </TableCell>
                                                    <TableCell className={'in-progress-status'}>{status}</TableCell>
                                                    {(this.state.yaraDownloadContent || this.state.vmrayScreenshotsExist) 
                                                    &&
                                                        <TableCell>
                                                        {
                                                            (type==="VMRAY" && this.state.vmrayScreenshotsExist) 
                                                            ? 
                                                                <Button 
                                                                    onClick={() => this.downloadVMRayScreenshots()} 
                                                                    className="download-details-btn"
                                                                >
                                                                    <DownloadRoundedIcon /> Sandbox Executable
                                                                </Button>
                                                            :
                                                            (
                                                                (type==="YARA" && this.state.yaraDownloadContent)
                                                                ?
                                                                <Button 
                                                                    onClick={() => this.downloadYARARules()} 
                                                                    className="download-details-btn"
                                                                >
                                                                    <DownloadRoundedIcon /> YARA Rules
                                                                </Button>
                                                                :
                                                                    ""
                                                            )
                                                        }
                                                        </TableCell>
                                                    }
                                                </>)
                                                :
                                                (<>
                                                        {reportName === '' ?
                                                            (<>
                                                                <TableCell>{type}</TableCell>
                                                            </>)
                                                            : type !== 'CUCKOO' ?
                                                        (<>
                                                            <TableCell className={'report-name-link'}>
                                                                <a href={"#"} onClick={() => this.openReport(tid, type)}>{type}</a>
                                                            </TableCell>
                                                        </>)
                                                        :
                                                        (<>
                                                            <TableCell>{type}</TableCell>
                                                        </>)
                                                    }
                                                    <TableCell>
                                                            {reportName !== '' ?
                                                        <input
                                                            id={index}
                                                            style={{marginTop: "4px"}}
                                                            name={"downloadBox"}
                                                            className="form-checks"
                                                            type="checkbox"
                                                            // value={props.sid}
                                                            onChange={(e) => this.handleDownloadCheckbox(e, index, status, reportUrl, location, tid, type)}
                                                            defaultChecked={false}
                                                        />
                                                                : ''
                                                            }
                                                    </TableCell>  
                                                    <TableCell className={'completed-status'}>{status}
                                                        { (reportName === '' && type.toLowerCase() === 'vmray') &&
                                                            <InfoRoundedIcon titleAccess="Dynamic report not produced for file type." size="lg" style={{color: "#f70000", marginLeft: "5px" }} />
                                                        }
                                                    </TableCell>
                                                    {(this.state.yaraDownloadContent || this.state.vmrayScreenshotsExist) 
                                                    &&
                                                        <TableCell>
                                                        {
                                                            (type==="VMRAY" && this.state.vmrayScreenshotsExist) 
                                                            ? 
                                                                <Button 
                                                                    onClick={() => this.downloadVMRayScreenshots()} 
                                                                    className="download-details-btn"
                                                                >
                                                                    <DownloadRoundedIcon /> Sandbox Executable
                                                                </Button>
                                                            :
                                                            (
                                                                (type==="YARA" && this.state.yaraDownloadContent)
                                                                ?
                                                                <Button 
                                                                    onClick={() => this.downloadYARARules()} 
                                                                    className="download-details-btn"
                                                                >
                                                                    <DownloadRoundedIcon /> YARA Rules
                                                                </Button>
                                                                :
                                                                    ""
                                                            )
                                                        }
                                                        </TableCell>
                                                    }
                                                </>)
                                            }
                                        </TableRow>
                                    )}
                                    return null;
                                })
                            }
                        </TableBody>
                    </Table>

                </TableContainer>
                {this.state.showModal &&
                    <RawReportModal
                        showModal={this.state.showModal}
                        handleClose={this.handleCloseModal}
                        reportName={this.state.displayedReportName}
                        reportContent={this.state.displayedReportContent}
                    />
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentTid: state.analysisReducer.currentTinfo ? state.analysisReducer.currentTinfo.tid : '',
        reportMetaDataForTid: state.analysisReducer.reportMetaDataForTid
    }
}

export default connect(mapStateToProps, null)(RawAnalysisTab);
