import React from 'react';
import  '../../../assets/css/AnalystTools/mar/tlp.css';
import {Icon, Popup} from "semantic-ui-react";

export function getTLP(tlp) {
    let getColor = () => {
        let color = "rgb(" + tlp.color_rgb_red + "," + tlp.color_rgb_green + "," + tlp.color_rgb_blue + ")";
        return color;
    }

    let isAmberStrict = tlp.traffic_light_protocol_cd === "Amber+Strict";
    let amberStrictLetter = "#003e67";

    return(
        <Popup
            trigger={
                <Icon
                    style={{
                        color: isAmberStrict ? amberStrictLetter : getColor(),
                        backgroundColor: isAmberStrict ? getColor() : ""
                    }}
                    className={isAmberStrict ? "tlp-color-icon-amber-strict" : "tlp-color-icon"}
                    name={isAmberStrict ? "stripe s" : "circle"}
                    circular={isAmberStrict}
                />
            }
            content={tlp.description}
            position="left center"
            style={{transform: "translate3d(0px, 0px, 0px)", width: "200px"}}
        />
    )
}

// export const rowsPerPageOptions = [10, 25, 50, 100];
export const rowsPerPageOptions = [25, 50, 75, 100];
