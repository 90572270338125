import React, {Component} from 'react'
import TransactionInfo from "./TransactionInfo/TransactionInfo";
import ReportTabs from "./ReportTabs";
import EmailReportTabs from "./EmailReportTabs";
import ReportRunner from "./ReportRunner";
import "../../../assets/css/FileAnalysisReport/FileAnalysisReportPage.css";
import ReportBreadcrumbs from "./ReportBreadcrumbs";
import * as actionTypes from "../../../redux/actions/analysisActions";
import {connect} from "react-redux";
import * as apiConnection from '../../../services/apiConnection';
import keycloak from '../../..';

class FileAnalysisReport extends Component {

    constructor(props) {
        super(props);
        this.state = {
          isSubmissionEmail: false,
          emailData: {},
        };  
        this.header = {
            'Authorization': 'Bearer ' + keycloak.token,
            "Content-Type": "application/json"
        };
    }

    componentDidMount() {
        this.checkIfSubmissionIsEmailFile();
    }

    checkIfSubmissionIsEmailFile = () => {
        const input = {
            tid: this.props.currentTid,
            type: "EMAIL ANALYSIS"
        }
        
        fetch(apiConnection.getEmailReportLocationUrl(), {
            method: "PUT",
            headers: {...this.header},
            body: JSON.stringify(input),
            credentials: "same-origin"
        })  .then(res => res.json())
            .then(resJson => {
                // console.log("email file? ", resJson);
                if(resJson.messages.length > 0) {
                    // console.log("not an email");
                    this.setState({isSubmissionEmail: false});
                    return;
                } else  {
                    this.setState({isSubmissionEmail: true});
                    let data = resJson.results[0][0];
                    this.getEmailFileJson(data.reportPath, data.reportName);
                }
            })
            .catch(err => console.log(err));
    }

    getEmailFileJson = (reportPath, reportName) => {
        let date = reportPath.replace('/mng/results/email-results/', '').slice(0, -1);

        // console.log("date before regex: ", date);

        // if date is not in YYYY-MM-DD format
        if( !date.match(/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/g) ) {
            // if month is only 1 number, prepend 0 (i.e. 2022-9-21 -> 2022-09-21)
            if( date.match(/(?<=-)[0-9]{1}(?=-)/g) ) {
                date = date.replace(/(?<=-)[0-9]{1}(?=-)/g, char => "0" + char);
            }
            // if day is only 1 number, prepend 0 (i.e. 2022-09-1 -> 2022-09-01)
            if( date.match(/(?<=-)[0-9]{1}$/g) ) {
                date = date.replace(/(?<=-)[0-9]{1}$/g, char => "0" + char);
            }
        }

        // console.log("date after regex: ", date);

        const input = {
            date_folder: date,
            file_name: reportName
        }

        fetch(apiConnection.getEmailAnalysisReport(), {
            method: "PUT",
            headers: {...this.header},
            body: JSON.stringify(input),
            // credentials: "same-origin"
        })  .then(res => {
                // console.log("res from getting email json: ", res)
                return res.json();
            })
            .then(resJson => {
                this.setState({emailData: resJson.results[0]});
            })
            .catch(err => console.log(err));
    }

    // If triggered, then true. Otherwise, false.
    handleEmailSubmission() {
        this.setState({isSubmissionEmail: true});
    }

    // after GA, this should be done in the database
    getVerdictColor(verdict) {
        switch (verdict) {
            case 'malicious':
                return "#99001A";
            case 'suspicious':
                return "#C29C28";
            case 'clean':
                return "#669900";
            case 'not_available':
                return "#8B7E74";
            default:
                return;
        }
    }

    handleVerdictValue(verdict) {
        // if verdict is null, return empty string
        if(!verdict) {
            return " ";
        }if(verdict==="not_available") {
            verdict="N/A";
        }
        return verdict.toUpperCase();
    }

    render() {
        const verdict = this.props.currentTinfo.threat ? this.props.currentTinfo.threat : null;

        return (
            <div className="far">
                <ReportRunner />
                {/* <ReportBreadcrumbs /> */}

                <table className="file-analysis-report-title">
                    <tbody>
                        <tr>
                            <td>
                                <h1>File Analysis Report</h1>
                            </td>
                            <td className="far-verdict verdict" style={{backgroundColor: this.getVerdictColor(verdict)}}>
                                    {this.handleVerdictValue(verdict)}
                            </td>
                        </tr>
                    </tbody>
                </table>

                <TransactionInfo  />

                {this.state.isSubmissionEmail 
                    ? <EmailReportTabs emailData={this.state.emailData} />
                    : <ReportTabs />
                }

            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentTid: state.analysisReducer.currentTinfo ? state.analysisReducer.currentTinfo.tid: '',
        currentTinfo: state.analysisReducer.currentTinfo
    }
}

const mapDispatchToProps = dispatch =>{
    return {
        onClearReportHistory: () => dispatch({type: actionTypes.CLEAR_REPORT_HISTORY})
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FileAnalysisReport);