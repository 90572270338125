import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
//Services
import * as apiConnection from "../../services/apiConnection.js";
import Spinner from "../common/Spinner";
import ErrorDisplay from '../SubmissionForm/ErrorDisplay';    
import FileUpload from "../SubmissionForm/FileUpload";
//Utils
import getFormData from "../SubmissionForm/utils/getFormDataForSubmission";
import "./../../App.css";
import SubmissionResult from './QuickSubmitMsg';
import keycloak from "../../index";
import {TextareaAutosize} from "@material-ui/core";
import TLPDropdown from "../tlp/TLPDropdown";
import FileUploadAndUrl from '../SubmissionForm/FileUploadAndUrl';
import Url from '../SubmissionForm/Url.jsx';
import CharLngthErrorDisplay from '../SubmissionForm/CharLngthErrorDisplay.js';

const file_input = {
  name: "files",
  reqObj: {
    required: "required",
    validate: {
      checkValidate: (value) => {
        // console.log("Checking file size...");
        let showErr = false;
        let total_bytes = 0;
        for (let i = 0; i < value.length; i++) {
          total_bytes += value[i].size;
          // console.log("File size " + total_bytes + " bytes.");
          if (total_bytes <= 100000000) {
            showErr = true;
          } else {
            showErr = false;
            break;
          }
        }
        return showErr;
      },
    },
  },
  errMsg: "File size  cannot exceed 100MB",
};

const urlInput = {
  controlID: "urlIputID",
  // labelField: "URL Input ID",
  name: "url_text",
  type: "text",
  placeholder: "URL...",
  required: "required",
  errMsg: "Invalid URL ",
  reqObj: { 
    validate: {
      checkValidate: (value) => {
        let showErr = false;
        //for (let i = 0; i < value.length; i++) {
        //  if(value){
            showErr = true;
        //  }
        //  return showErr;
        //}
      }
    }
  }
}

const incidentId = {  
  controlID: "formIncidentID",
  maxChar: 50,
    //labelField: "Incident ID",
  type: "text",
  name: "incident_id",
  placeholder: "Incident ID",
  errMsg: "Incident ID is Required ",
  reqObj: { maxLength: 50 },
}

const descriptionId = {
  controlID: "formCommentID",
  //labelField: "Incident ID",
  type: "text",
  name: "description",
  placeholder: "Comments"
  // errMsg: "Incident ID is Required ",
  // reqObj: { maxLength: 20 },
}

const validation = (register, paramObjs) => {
  return register(paramObjs);
};



/**
 * MAIN FUNCTION COMPONENT
 */
const QuickSubmission = (props) => {

  const { register, errors, handleSubmit } = useForm({ mode: "onChange" });
  const [fieldValue, setFieldValue] = useState({});
  const [displayCharacterLength, setDisplayCharacterLength] = useState(0 + '/500');
  const [characterLength, setCharacterLength] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [isFileLoadSuccess, setIsFileLoadSuccess] = useState(null);
  const [electionRelated, setElectionRelated] = useState("");
  const [selectedTLP, setSelectedTLP] = useState("GREEN"); // default tlp state
  const [uploadButtonClicked, setUploadButtonClicked] = useState(true);
  const [urlButtonClicked, setUrlButtonClicked] = useState(false);
  const [urlValue, setUrlValue] = useState("");
  const [isUrlSuccess, setIsUrlSuccess] = useState(null)
  const [urlError, setUrlError] = useState(null)
  const [submitButtonClicked, setSubmitButtonClicked] = useState(false);
  const [urlDisplayCharacterLength, setDisplayUrlCharacterLength] = useState(0 + '/2048');
  const [urlCharacterLength, setUrlCharacterLength] = useState(0);
  const [displayCharLength, setDisplayCharLength] = useState(null);
  const [charLength, setCharLength] = useState(0);
  const [inputName, setCurrentInput] = useState({});

  const onSubmit = (data, e) => {
    setSubmitButtonClicked(true);
    e.preventDefault();
    setIsProcessing(true);
    setDisplayCharacterLength(0 + '/500');
    setCharacterLength(0);
    //setUploadButtonClicked(true);
    //setUrlButtonClicked(false);
    data.traffic_light_protocol_cd = selectedTLP;
    data.user_id = userInfo.user_id;
    data.first_name = userInfo.first_name;
    data.last_name = userInfo.last_name;
    data.email = userInfo.email;
    data.organization = userInfo.organization;
    data.phone_number = userInfo.phone_number;
    data.election_related_ind = electionRelated;

    if (urlValue) {
      data.source = "url";
      data.url = urlValue
      let apiUrl = apiConnection.getUploaderUrl();
      // console.log("Data Sent on Submittion:   " + JSON.stringify(data) );

      const formData = getFormData(data);
      // console.log("Data Sent on Submittion:   " + 
      // JSON.stringify(Object.fromEntries(formData.entries())) );

      setElectionRelated("");

      let header = {
        'Authorization': 'Bearer ' + keycloak.token
      };

      // using Fetch
      fetch(apiUrl, {
        method: "POST",
        headers: header,
        body: formData,
        credentials: "same-origin"
      })
        .then(response => response.json())
        .then(result => {
          setIsProcessing(false);
          if (result.data) {
            setIsUrlSuccess(true);
            setUrlError(false);
          } else {
            if (result.errors[0]) {
              setIsUrlSuccess(false);
              setUrlError(true);
            }
          }
          props.handleSubmitted(true);
          resetURLFormData();
        })
        .catch((err) => {
          console.error("URL Fetch Submission : " + e);
          setIsProcessing(false);
          setIsUrlSuccess(false);
          setUrlError(true);
        });
    } else if (data.files) {
      // console.log("Data Sent on Submittion:   " + JSON.stringify(data) );
      const formData = getFormData(data);
      // console.log("Data Sent on Submittion:   " + 
      // JSON.stringify(Object.fromEntries(formData.entries())) );

      setElectionRelated("");
      let apiUrl = apiConnection.getSubmissionUrl();

      let header = {
        'Authorization': 'Bearer ' + keycloak.token
      };

      // using Fetch
      fetch(apiUrl, {
        method: "POST",
        headers: header,
        body: formData,
        credentials: "same-origin"
      })
        .then(response => response.json())
        .then(result => {
          setIsProcessing(false);
          if (result.data) {
            setIsFileLoadSuccess(true);
          } else {
            if (result.errors[0]) {
              setIsFileLoadSuccess(false);
            }
          }
          props.handleSubmitted(true);
        })
        .catch((err) => {
          console.error("File Load Fetch Submission : " + e);
          setIsProcessing(false);
          setIsFileLoadSuccess(false);
        })
    }

    if (!urlValue && !data.files && urlButtonClicked) {
      setIsProcessing(false);
    } else if (uploadButtonClicked && data.files) {
      resetFileFormData();
      e.target.reset();
    }
    setUrlCharacterLength(0);    
    setDisplayUrlCharacterLength(urlCharacterLength + '/2048');
  }

  const resetURLFormData = () => {
    setFieldValue({});
    setSelectedTLP("GREEN");
    setUrlValue("");
    setUrlButtonClicked(false);
    setUrlCharacterLength(0);    
    setDisplayUrlCharacterLength(urlCharacterLength + '/2048');
    setUrlButtonClicked(true);
    setSubmitButtonClicked(false);
    setDisplayCharLength('');
  }

  const resetFileFormData = () => {
    //setIsFileLoadSuccess(false);
    //setIsUrlSuccess(false);
    setFieldValue({});
    // set default tlp of "green"
    setSelectedTLP("GREEN");
    setUrlValue("");
    setUrlButtonClicked(false);
    setUrlCharacterLength(0);    
    setDisplayUrlCharacterLength(urlCharacterLength + '/2048');
    setUploadButtonClicked(true);
    setSubmitButtonClicked(false);
    setDisplayCharLength('');
  }

  const fileUploadButtonClicked = (e) => {
    // setUploadButtonClicked(!uploadButtonClicked);
    setUploadButtonClicked(true);
    //setIsFileLoadSuccess(null);
    setIsUrlSuccess(null);
    setUrlError(null);
    setUrlButtonClicked(false);
    setUrlError(false);
    setUrlValue("");
  }

  const urlBtnClicked = (e) => {
    setSubmitButtonClicked(false);
    //setIsFileLoadSuccess(null);
    setIsUrlSuccess(null);
    setUrlError(null);
    setUploadButtonClicked(false);
    setUrlValue("");
    if (urlBtnClicked === true) {
      setUploadButtonClicked(true);
      setUrlButtonClicked(false);
    } else {
      setUrlButtonClicked(true);
    }
//    if (isUrlSuccess === false && urlError === true ){
//      setUploadButtonClicked(false);
//      setUrlButtonClicked (true);
//    }
  }

  const handleUrl = (e) => {
    const { target } = e;
    const { name, value } = target;
    const urlCharacterLengthLoc = value.length;
    setDisplayUrlCharacterLength(urlCharacterLengthLoc + '/2048');
    if ( urlCharacterLengthLoc > 2047 ) {
      setDisplayUrlCharacterLength( " Maximum limit reached"  );
    }
    setUrlCharacterLength( value.length );
    if ( urlCharacterLengthLoc < 2049 ) {
      setUrlValue(value);
    } else {
      setUrlValue("");
    }
  }

  const handleFileChange = (event) => {
    const target_files = event.target.files;
    if (target_files.length < 0) {
      return;
    }
  };

  const handleCharacterLimit = (e) => {
    const { target } = e;
    const { name, value } = target;
    const characterLengthLoc = value.length;
    setDisplayCharacterLength( characterLengthLoc + '/500');
    if ( characterLengthLoc > 499 ) {
      setDisplayCharacterLength( " maximum limit reached"  );
    }
    setCharacterLength( value.length );
  };

  const handleFieldChange = (e, maxLength) => {   
    const { target } = e;
    const { name, value } = target;
    fieldValue[name] = value;
    setCurrentInput(name);
    setFieldValue({ ...fieldValue }, fieldValue[name]);
    if (name !== 'phone_number') {
      const charLengthLoc = value.length;
      setDisplayCharLength( charLengthLoc + '/' + maxLength);
      if ( charLengthLoc > (maxLength - 1) ) {
        setDisplayCharLength( "Maximum limit reached"  ); 
      }
      setCharLength( value.length );
    }
  };

  React.useEffect(() => {
    let timer = null;
      // Hide result message after 20 seconds
    timer = setTimeout(() => {
      setIsFileLoadSuccess(null);
      setIsUrlSuccess(null);
      // setUrlValue("");
      // setUrlButtonClicked(false);
      // setUploadButtonClicked(true);
      // setUrlCharacterLength(0);    
      setDisplayUrlCharacterLength(urlCharacterLength + '/2048');
      setSubmitButtonClicked(false);
    }, 20000); //timer in milliseconds

    return () => clearTimeout(timer);
  }, [isFileLoadSuccess, isUrlSuccess]);

  let handleSelectedTLP = (tlpSelected) => {
    setSelectedTLP(tlpSelected.toLowerCase());
  }

  React.useEffect(() => {
    let isSubscribed = true;

    //console.log("QuickSubmission Calling loadUserProfile...");

    let user = {};

    user.user_id = keycloak.tokenParsed.preferred_username;
    user.email = keycloak.tokenParsed.email;
    user.name = keycloak.tokenParsed.name;
    user.first_name = keycloak.tokenParsed.given_name;
    user.last_name = keycloak.tokenParsed.family_name;
    user.organization = keycloak.tokenParsed.organization_name;
    user.phone_number = keycloak.tokenParsed.phone_number;

    if (isSubscribed) {
      setUserInfo({ ...user });
    }

    //user == null ? console.log("User Profile Error") : ''; //: console.log("User Profile = ", user) ; 

    return () => { isSubscribed = false; }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  if (isProcessing) {
    return <Spinner></Spinner>;
  }
  if (!isProcessing) {
    return (
        <div>
          <div className="body-container">
            <div className="home-header">
              <h4>Quick Submit</h4>
            </div>

            <Form className="quick-form" onSubmit={handleSubmit(onSubmit)}>
              <Form.Row>
                <Form.Group as={Col} controlId="formFile">
                  {/* <Form.Label>File Upload</Form.Label>
                  <FileUpload
                    handleFileChange={(e) => handleFileChange(e)}
                    refs={validation(register, file_input.reqObj)}
                    name={file_input.name}
                    ariaInvalid={errors[file_input.name] ? "true" : "false"} /> */}
                  {/* <Form.Text> */}
                <FileUploadAndUrl
                    fileUploadButtonClicked={(e) => fileUploadButtonClicked(e)}
                    urlBtnClicked={(e) =>  urlBtnClicked(e)}
                />
                  {uploadButtonClicked &&  
                    <FileUpload
                      handleFileChange={(e) => handleFileChange(e)}
                      refs={validation(register, file_input.reqObj)}
                      name={file_input.name}
                      ariaInvalid={errors[file_input.name] ? "true" : "false"} 
                    />
                  }
                  {uploadButtonClicked &&
                    <ErrorDisplay field={file_input} errors={errors} />
                  }
                  {urlButtonClicked && 
                    <Url
                      submitButtonClicked={submitButtonClicked}
                      setDisplayUrlCharacterLength={setDisplayUrlCharacterLength}
                      urlCharacterLength={urlCharacterLength}
                      urlDisplayCharacterLength={urlDisplayCharacterLength}
                      handleUrl={(e) => handleUrl(e)}
                      refs={validation(register, urlInput.reqObj)}
                      name={urlInput.name}
                      urlInput={urlInput}
                      ariaInvalid={errors[urlInput.name] ? "true" : "false"} 
                    />
                  }
                  <Form.Text>
                    {isUrlSuccess === false && urlError === true && 
                      <p className="help-text">Invalid URL</p>
                    }
                    {!urlValue && submitButtonClicked && urlBtnClicked &&
                      <p style={{color: 'red',  }}>
                        No URL entered
                      </p>
                    }
                  </Form.Text>
                </Form.Group>

                <Form.Group as={Col} controlId="formTLP">

                  <Form.Group as={Col} controlId="formTLP" >
                    <Form.Label style={{width: "inherit"}}>
                      Traffic Light Protocol
                    </Form.Label>
                    <TLPDropdown 
                      quickSubmit={true}
                      selectedTLP={handleSelectedTLP}
                    />
                  </Form.Group>

                  <Form.Group as={Col} style={{marginTop: "53px"}}>
                    <label >
                      Election Related? &nbsp;
                    </label>
                    <input
                      aria-label="election related aria-label for quick submission form"
                      name={'election related name for quick submission form'}
                      onChange={(e) => setElectionRelated(e.target.checked? 'T' : "")}
                      id="gridCheck"
                      // checked={election_trlated}
                      type="checkbox"
                    />
                  </Form.Group>
                </Form.Group>

                <Form.Group as={Col} controlId="formComment">
                  <Form.Label style={{width: "inherit"}}>
                    Comments 
                    <span style={{float: "right", color: characterLength<500 ? (characterLength==0 ? "gray" : "green") : "red"}}>
                      {displayCharacterLength}
                    </span>
                  </Form.Label>
                  <TextareaAutosize
                      controlid={descriptionId.controlID}
                      className="autosize-textarea"
                      name={descriptionId.name}
                      defaultValue={""}
                      value={fieldValue[descriptionId.name]}
                      maxLength="500"
                      onChange={(e) => handleCharacterLimit(e)}
                      aria-label={descriptionId.labelField}
                      aria-required={descriptionId.required}
                      placeholder={descriptionId.placeholder}
                      style={{padding: '5px', fontSize: '1rem', height: "35.55px"}}
                      ref={validation(register)}
                  />
                  <SubmissionResult 
                  isFileLoadSuccess={isFileLoadSuccess}
                  isUrlSuccess={isUrlSuccess}
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="formInidentID">
                  <Form.Label style={{width: "inherit"}}>
                    Incident ID
                    <span style={{ float: 'right', textAlign: "right", color: charLength < incidentId.maxChar ? (charLength === 0 ? "gray" : "green") : "red" }}>
                      {displayCharLength}
                    </span>
                    <CharLngthErrorDisplay field={incidentId} errors={errors} />
                  </Form.Label>
                  <Form.Control
                    controlid={incidentId.controlID}
                    className={"form-group-inputs"}
                    type={incidentId.type}
                    name={incidentId.name}
                    defaultValue={""}
                    value={fieldValue[incidentId.name]}
                    onChange={(e) => handleFieldChange(e, incidentId.maxChar)}
                    aria-invalid={errors[incidentId.name] ? "true" : "false"}
                    aria-label={incidentId.labelField}
                    aria-required={incidentId.required}
                    placeholder={incidentId.placeholder}
                    maxLength={incidentId.maxChar}
                    ref={validation(register, incidentId.reqObj)}
                    style={{height: "35.55px"}}
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="formSubmitBtn">
                  <Form.Label></Form.Label>
                  <div>
                    <Button
                      aria-label="submit"
                      aria-required="true"
                      size="sm"
                      className="quick-submission-button"
                      variant="primary"
                      type="submit">
                      Submit
                    </Button>
                  </div>
                </Form.Group>

              </Form.Row>
            </Form>
          </div>
        </div>
    );
  }
}

export default QuickSubmission;
