// React
import React, {useEffect, useState} from 'react';
import * as info1 from "./feedbackDetails.js";
import * as info2 from "./feedbackDetails2.js";

// Libraries
import {Table, TableContainer, TableCell, TableHead, TableBody, TableRow} from "@material-ui/core";
import {Row, Col, Button, Form} from "react-bootstrap";
import {TextareaAutosize} from "@material-ui/core";

// Services
import * as apiConnection from "../../../../services/apiConnection";
import keycloak from "../../../../index";
import { hasMSISACAccess } from '../../../../utils/PermissionUtil.js';

const FeedbackTab = (props) => {

    const [feedbackInput, setFeedbackInput] = useState("");
    const [listOfFeedbacks, setListOfFeedbacks] = useState([]);
    const [subMsgInfo, setSubMsgInfo] = useState([false, "", "."]); /* submission message info */
    const [charCount, setCharCount] = useState(0);
    const header = {'Authorization': 'Bearer ' + keycloak.token};

    let info = hasMSISACAccess() ? info2 : info1;

    useEffect(() => {
        fetchMifrFeedback();
    }, [])

    const fetchMifrFeedback = () => {
        const input = { tid: props.tid }

        fetch(apiConnection.getMifrFeedback(), {
            method: "PUT",
            headers: {...header, "Content-Type": "application/json"},
            body: JSON.stringify(input),
        })
        .then(res => {
            if(res.statusText === "NO CONTENT") {
               /* do nothing, since nothing to fetch */
            } else {
                return res.json();
            }
        })
        .then(resJson => {
            if(!resJson || resJson == undefined) {return}
            if(resJson) {
                if(resJson.errors && resJson.errors.length > 0) {
                    setSubMsgInfo([true, "red", resJson.errors[0]]);
                } else if(resJson.messages && resJson.messages.length > 0) {
                    setSubMsgInfo([true, "red", resJson.messages[0]]);
                } else if(resJson.results && resJson.results.length > 0) {
                    setListOfFeedbacks(resJson.results[0]);
                }
            }
        })
        .catch(err => console.error("Error in fetching MIFR feedback: ", err));
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if(charCount > 2000) {
            setSubMsgInfo([true, "red", "Too long."]);
            return;
        }

        if(feedbackInput.length === 0) { return }

        const input = {
            tid: props.tid, 
            feedback: feedbackInput, 
            user_id: keycloak.tokenParsed.preferred_username
        }

        fetch(apiConnection.uploadMifrFeedback(), {
            method: "POST",
            headers: {...header, "Content-Type": "application/json"},
            body: JSON.stringify(input),
        })
        .then(res => res.json())
        .then(resJson => {
            if(!resJson || (resJson == undefined)) {
                setSubMsgInfo([true, "red", "Error: response from service is undefined."]);
            } else if (resJson) {
                if(resJson.errors && resJson.errors.length > 0) {
                    setSubMsgInfo([true, "red", resJson.errors[0]]);
                } else if(resJson.messages && resJson.messages.length > 0) {
                    setSubMsgInfo([true, "red", resJson.messages[0]]);
                } else if(resJson.results && resJson.results.length > 0) {
                    setSubMsgInfo([true, "green", "Thanks for your input."]);
                }
            }
            setCharCount(0);
            fetchMifrFeedback();
        })
        .catch(err => console.error("Error in uploading MIFR Feedback: ", err));

        setFeedbackInput("");
    }

    const handleChange = (e) => {
        const input = e.target.value;
        setCharCount(input.length);
        setFeedbackInput(input);
        setSubMsgInfo([false, "", "."]);
    }

    const handleClick = () => {
        setSubMsgInfo([false, "", "."]);
    }

    const handleKeyPress = (e) => {
        if(e.which == 13) {
            e.preventDefault();
            handleSubmit(e);
        }
    }

    return (
        <div>
            <Form onSubmit={e => handleSubmit(e)}>
                <Form.Group>
                    <Row className="justify-content-md-center" style={{marginTop: "30px"}}>
                        <Col md="5" className="mifr-feedback-imp-msg">
                            <div className="mifr-feedback-contact-title">Important</div>
                            {info.cisaContactMsg}
                            <Row className="justify-content-md-center align-items-center">
                                {info.cisaContactMethods.map( (contactItem, index) => 
                                    <Col key={index} md={contactItem.colWidth} className="mifr-feedback-contact-item">
                                        <b>{contactItem.title}</b>
                                        <br/> 
                                        <a className="mifr-feedback-contact-val">{contactItem.display}</a>
                                    </Col>
                                )}
                            </Row>
                            <br />
                            { hasMSISACAccess() ? info2.analyzeHelpMsg : ""}
                        </Col>

                        <Col md="6" style={{padding: "0 0 0 20px"}}>
                            <Form.Text style={{fontSize: "15px"}}>
                                {info.feedbackEntryInstructions}
                            </Form.Text>
                            <Form.Text className="mifr-feedback-char-counter"
                                style={{color: charCount<2000 ? (charCount==0 ? "gray" : "green") : "red"}}
                            >
                                {charCount}/2000
                            </Form.Text>
                            <TextareaAutosize
                                minRows={4}
                                style={{resize: "none"}}
                                name="feedback"
                                value={feedbackInput}
                                onChange={e => handleChange(e)}
                                onClick={() => handleClick()}
                                onKeyPress={e => handleKeyPress(e)}
                                className="autosize-textarea"
                            /> 
                            <Form.Text className="mifr-feedback-submit-msg" style={{color: subMsgInfo[1]}}>
                                <p style={{visibility: subMsgInfo[0] ? "visible" : "hidden"}}>
                                    {subMsgInfo[2]}
                                </p> 
                            </Form.Text>
                            <br />
                            <br />
                            <Button type="submit" size="sm" className="mifr-feedback-submit-btn">
                                Submit
                            </Button>
                        </Col>
                    </Row>
                </Form.Group>
            </Form>
            
            {listOfFeedbacks && listOfFeedbacks.length > 0 &&

                <TableContainer id={'related-files'} style={{marginTop: "-10px"}}>
                    <br />
                    <br />
                    <Table aria-label="feedback-table" size='small'>
                        <TableHead >
                            <TableRow >
                                <TableCell>Feedback</TableCell>
                                <TableCell>User</TableCell>
                                <TableCell>Submitted</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {listOfFeedbacks && listOfFeedbacks.slice(0).reverse().map( (data, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell>{data.feedback}</TableCell>
                                        <TableCell>{data.user_id}</TableCell>
                                        <TableCell>{data.created_ts}Z</TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            }
        </div>
    )  
}

export default FeedbackTab;