import React, {Component} from 'react';
import {Icon, Menu} from "semantic-ui-react";
import mitreIcon from "../../../assets/img/reportPage/mitre_icon.png";
import networkActivityIcon from "../../../assets/img/reportPage/network_activity_icon.png";
import '../../../assets/css/Sidebar.scss';
import CISA_LOGO from "../../../assets/img/logo/cisa_logo.png";
import keycloak from "../../../index";
import * as httpCalls from "../../../services/httpCalls";
import {connect} from "react-redux";
import * as apiConnection from "../../../services/apiConnection";
import DownloadStixErrorPopup from './DownloadStixErrorPopup';
import {hasMSISACAccess} from '../../../utils/PermissionUtil';

/**
 * SideBar component for IMIFR 
 */
// export class SideBar extends React.Component {
class SideBar extends Component {
    constructor(props) {
      super(props);
      this.state = {
        showDownloadStixErrorPopup: false,
        yaraDownloadContent: "",
      }
      this.handleCloseModal = this.handleCloseModal.bind(this);
      this.headers = {
        'Authorization': 'Bearer ' + keycloak.token,
        "Content-Type": "application/json"
      };
    }
    
    componentDidMount() {
      this.checkForYARARules();
    }

    handleCloseModal() {
      this.setState({ showDownloadStixErrorPopup: false });
    }

    handleMSISACAssistanceRequest = () => {
      let hrefValue="mailto:" + process.env.REACT_APP_MS_ISAC_EMAIL;
      hrefValue += "?subject=" + this.props.tid;
      hrefValue += "&body=Dear MS-ISAC Team,";
      // console.log("href=" + hrefValue);
      return hrefValue;
    }

    checkForYARARules() {
      let input = {
          tid: this.props.currentTid,
      }
      fetch(apiConnection.downloadYaraRuleMatches(), {
          method: "PUT",
          headers: {...this.headers},
          credentials: "same-origin",
          body: JSON.stringify(input)
      })
          .then(res => res.json())
          .then(res => {
              if(res.messages && res.messages[0] === "No YARA matches") {
                  this.setState({yaraDownloadContent: null});
                  return;
              }
              if(res.results && res.results.length > 0) {
                  this.setState({yaraDownloadContent: res.results[0]});
              }
          })
          .catch(err => console.log("Fetch Submission : " + err));
    }

    downloadYARARules() {
      var link = document.createElement('a');
      link.download = "YARA_Rules_" + this.props.currentTid + ".txt";
      var blob = new Blob([this.state.yaraDownloadContent], {type: 'text/plain'});
      link.href = window.URL.createObjectURL(blob);
      link.click();
    }

    downloadStixReport = () => {
      const hasStixError = this.props.showDownloadStixErrorPopup;
      
      if(hasStixError) {
        this.setState({ showDownloadStixErrorPopup: true });
      }
      else {
        let input = {
          tid: this.props.tid,
          type: "STIX21"
        }
        const reportUrl = apiConnection.viewReportUrl();
        const reportFileName = this.props.stixFileNameForDownload;
  
        httpCalls
            .configureAxiosRequest(reportUrl, {...this.headers}, "PUT", input)
            .then((res) => {
                if (res.data) {
                    const processedData = JSON.stringify(res.data);
                    const link = document.createElement('a');
                    link.setAttribute("href", "data:text;charset=utf-8," + encodeURIComponent(processedData));
                    link.setAttribute('download', reportFileName);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                }
            })
            .catch((err) => {
                console.error("Download STIX 2.1 report request - err: " + err);
            });
      }
    }

    fixScroll = (sectionId) => {
      const id = sectionId;
      const yOffset = 166;
      const element = document.getElementById(id);
      const y = element.getBoundingClientRect().top + window.pageYOffset - yOffset;
      window.scrollTo({top: y, behavior: "smooth"});
    }

    render() {
        return (
          <div>
          <Menu className='side-nav' borderless vertical>
            <span title="Back to top" className="back-to-top-icon" onClick={()=>window.scrollTo(0, 0)}>
              <Icon size='large' name='caret square up outline'/>
            </span> 
            <div className="container">
              <img src={CISA_LOGO}/>
            </div>
            <div style={{width: "70%", margin: "auto"}}>
            <Menu.Item className={['sidebar-item', 'highlight-item'].join(' ')}>
              <div className='sidebar-item-alignment-container'>
                <span><Icon size='large' name='file alternate outline'/></span> 
                <span>
                  <a className="mifr-sidebar-item" onClick={()=>this.fixScroll("Summary")}>Summary</a>
                </span>  
              </div>
            </Menu.Item>
            <Menu.Item className={['sidebar-item', 'highlight-item'].join(' ')}>
              <div className='sidebar-item-alignment-container'>
                <span><Icon size='large' name='chart bar'/></span> 
                <span>
                  <a className="mifr-sidebar-item" onClick={()=>this.fixScroll("Static Analysis")}>Static Analysis</a>
                </span> 
             </div>
            </Menu.Item>
            <Menu.Item className={['sidebar-item', 'highlight-item'].join(' ')}>
               <div className='sidebar-item-alignment-container'>
                <span><Icon size='large' name='bug'/></span> 
               <span>
                 <a className="mifr-sidebar-item" onClick={()=>this.fixScroll("Dynamic Analysis")}>Dynamic Analysis</a>
                </span>      
               </div>
            </Menu.Item>
            {this.props.isUrl 
              ?
                <Menu.Item className={['sidebar-item', 'highlight-item'].join(' ')}>
                 <div className='sidebar-item-alignment-container'>
                   <span><Icon size='large' name='globe'/></span> 
                   <span>
                     <a className="mifr-sidebar-item" onClick={()=>this.fixScroll("URL Analysis")}>URL Analysis</a>
                   </span>
                 </div>
                </Menu.Item>
              :
                <Menu.Item className={['sidebar-item', 'highlight-item'].join(' ')}>
                 <div className='sidebar-item-alignment-container'>
                   <img src={networkActivityIcon} width={'18px'} height={'18px'} className="mifr-sidebar-icon"/> 
                   <span>
                     <a className="mifr-sidebar-item" onClick={()=>this.fixScroll("Network Activity")}>Network Activity</a>
                   </span>
                 </div>
                </Menu.Item>
            }
            <Menu.Item className={['sidebar-item', 'highlight-item'].join(' ')}>
               <div className='sidebar-item-alignment-container'>
                 <img src={mitreIcon} width={'18px'} height={'18px'} className="mifr-sidebar-icon"/>
                 <span>
                   <a className="mifr-sidebar-item" onClick={()=>this.fixScroll("MITRE ATT&CK Characterization")}>MITRE ATT&CK Characterization</a>
                </span>
               </div>
            </Menu.Item>
            <Menu.Item className={['sidebar-item', 'highlight-item'].join(' ')}>
              <div className='sidebar-item-alignment-container'>
                <span><Icon size='large' name='shield alternate'/></span> 
                <span><a className="mifr-sidebar-item" onClick={()=>this.fixScroll("Mitigation Recommendations")}>Mitigation</a></span>
             </div>
            </Menu.Item>
            <Menu.Item className={['sidebar-item', 'highlight-item'].join(' ')}>
              <div className='sidebar-item-alignment-container'>
                <span><Icon size='large' name='download'/></span>
                <span>
                  <a className="mifr-sidebar-item" onClick={()=>this.downloadStixReport()}>STIX 2.1</a>
                </span>
             </div>
            </Menu.Item>
            {this.state.yaraDownloadContent &&
              <Menu.Item className={['sidebar-item', 'highlight-item'].join(' ')}>
                <div className='sidebar-item-alignment-container'>
                  <span><Icon size='large' name='download'/></span>
                  <span>
                    <a className="mifr-sidebar-item" onClick={()=>this.downloadYARARules()}>YARA Rules</a>
                  </span>
               </div>
              </Menu.Item>
            }
            {/* {hasMSISACAccess() &&
              <Menu.Item className={['sidebar-item', 'highlight-item'].join(' ')}>
                <div className='sidebar-item-alignment-container'>
                  <span><Icon size='large' name='mail'/></span>
                  <span>
                    <a className="mifr-sidebar-item" href={this.handleMSISACAssistanceRequest()}>Request MS-ISAC Assistance</a>
                  </span>
                </div>
              </Menu.Item>
            } */}
            </div>   
          </Menu>

          {this.state.showDownloadStixErrorPopup && 
            <DownloadStixErrorPopup
              showModal={this.state.showDownloadStixErrorPopup}
              handleClose={this.handleCloseModal}
              isStixEmpty={this.props.isStixEmpty}
              stixStatus={this.props.stixStatus}
            />
          }
          </div>
        );
    }
}

const mapStateToProps = state => {
  return {
      currentTid: state.analysisReducer.currentTinfo ? state.analysisReducer.currentTinfo.tid: '',
      reportMetaDataForTid: state.analysisReducer.reportMetaDataForTid
  }
}

export default connect(mapStateToProps, null)(SideBar);