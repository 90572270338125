import React, { Component, createElement } from "react";

import { DropFileModel } from './DropFileModel';
import { ExeMetaDataModel } from "./ExeMetaDataModel";
import { PdfMetaDataModel } from "./PdfMetaDataModel";
import { DroppedFile } from "./DroppedFile";
import { AttackPatternTagModel } from "./AttackPatternTagModel.jsx";
import { AttackPatternTag } from "./AttackPatternTag";
import MifrSectionHeader from "./MifrSectionHeader";
import VMRayScreenshotModal from "./VMRayScreenshotModal";
import {hasMSISACAccess} from "../../../utils/PermissionUtil";
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import "../../../assets/css/MifrReport.css";
import keycloak from "../../../index";
import {validateUUID} from "../../../utils/Validation";
import PrintIcon from "@mui/icons-material/Print";
import FileDownloadRoundedIcon from "@mui/icons-material/FileDownloadRounded";
import {Icon} from "semantic-ui-react";
import Collapse from "@material-ui/core/Collapse";
import {Button, Card, Row} from "react-bootstrap";
import CISA_LOGO from "../../../assets/img/logo/cisa_logo.png";
import {connect} from "react-redux";

import * as httpCalls from "../../../services/httpCalls";
import 'bootstrap/dist/css/bootstrap.min.css';

//Services
import * as apiConnection from "../../../services/apiConnection.js";

const NOTIFICATION = "Notifications";
const FILEINFO = "File Info";
const ANTIVIRUS = "Antivirus";
const MITIGATION = "Mitigation Recommendations";

class MifrReport extends Component {
  /**
    *  Constructor
  */
  constructor(props) {
    super(props);

    this.state = {
      tags: '',
      tid: '',
      tlp: '',
      fileName: '',
      stixFilePath: '',
      notification: [],
      fileInfo: {},
      urlInfo: {},
      antivirus: [],
      avSummary: "",
      metadata: [],
      dropFile: [],
      attackPatternTag: [],
      attackPattern: [],
      history: [],
      mitigation: [],
      yara: [],
      behaviorAnalysis: [],
      networkAnalysis: [],
      httpDataForNetworkAnalysis: {},
      urlAnalysis: {},
      isPdf: false,
      summaryOpen: true,
      staticAnalysisOpen: true, 
      dynamicAnalysisOpen: true,
      networkActivityOpen: true,
      urlAnalysisOpen: true,
      mitreCharacterizationOpen: true,
      mitigationOpen: true,
      printing: false,
      isUrl: false,
      showVMRayScreenshotModal: false,
      indexOfClickedImage: "",
      screenshotData: []
    };

    this.ref = React.createRef(null);
  }

  
  /**
   * This method gets called after components has mounted.
   * That is after all elements on the page rendered correctly.
   */
  componentDidMount(props) {
    const filePath = this.props.filePath;
    this.setState({tid: this.props.tid});
    this.setup(filePath);
    this.getVMRayScreenshots();
  }

  setupHTMLDownload() {
    const element = document.createElement("a");

    let selectMifr = document.getElementById("mifr-report-page");

    // clone MIFR 
    let clone = selectMifr.cloneNode(true);

    // set ID to avoid node confusion
    clone.id = "mifr-report-page-clone";

    clone.style.margin = "20px 80px 100px 80px";

    // reformat VMRay screenshots so their sizes are larger
    if(this.state.screenshotData.length>0) {
      let vmrayScreenshotDownloadAllBtn = clone.querySelector("#vmray-screenshots-download-all-btn");
      vmrayScreenshotDownloadAllBtn.style.display = "none";
  
      let vmrayScreenshotCards = clone.querySelectorAll("#vmray-screenshot-card");
      vmrayScreenshotCards.forEach(card => {
        card.style.width = "auto";
      })
  
      let vmrayScreenshotImages = clone.querySelectorAll(".vmray-screenshot-image");
      vmrayScreenshotImages.forEach(image => {
        image.style.height = "auto";
        image.addEventListener('mouseenter', () => {
          image.style.opacity = 1;
          image.style.cursor = "auto";
        })
      })
    }

    // hide collapse/expand icons on section header
    let collapseIconList = clone.querySelectorAll("#mifr-section-collapse");
    collapseIconList.forEach(collapseIcon => {
      collapseIcon.style.display = "none"
    })

    let cisaLogo = clone.querySelector("#cisa-logo-mifr-html");
    cisaLogo.style.display = "revert";

    // add CISA logo image (this code is required for external download)
    let imgElement = clone.querySelector("#cisa-logo-html");
    
    if(imgElement) {
      let imgSrc = imgElement.getAttribute("src");
      fetch(imgSrc)
        .then(response => response.blob())
        .then(blob => {
          let reader = new FileReader();
          reader.onload = () => {
            imgElement.src = reader.result;
            this.createAndDownloadHTML(clone.outerHTML);
          }
          reader.readAsDataURL(blob);
        })
    } else {
      this.createAndDownloadHTML(clone.outerHTML);
    }
  }

  createAndDownloadHTML(htmlSnippet) {
    const styleSheets = document.styleSheets;
    let allStyles = "";

    // apply all styles from MIFR's .css and inline
    for(let i=0; i<styleSheets.length; i++) {
      const styleSheet = styleSheets[i];
      const rules = styleSheet.cssRules;
      for(let j=0; j<rules.length; j++) {
        const rule = rules[j];
        allStyles += rule.cssText + "\n";
      }
    }
    const styleBlock = `<style>${allStyles}</style>`;
    htmlSnippet += styleBlock;
    const file = new Blob([htmlSnippet], {type: "text/html"});
    const element = document.createElement("a");
    element.href = URL.createObjectURL(file);

    element.download = "MIFR_" + this.state.tid + "_report.html";
    document.body.appendChild(element);  //required for firefox
    element.click();
  }

  /**
   * 
   * This method gets called from its child component 
   */
  update = (mifrData) => {
    
    const historyData = {
      tid: this.state.tid,
      stixPath: this.state.stixFilePath
    }

    this.setState(prevState => ({
      history: [...prevState.history, historyData]
    }))

    this.setState({tid: mifrData.tid});
    this.setup(mifrData.stixPath);
  }

  setup(stixPath) {
    // window.scrollTo(0,0);
    this.setState({stixFilePath:stixPath})
    const apiUrl = apiConnection.getMifrReport();

    // HARDCODED FOR VMRAY TESTING
    // REMOVE LATER

    // let filePath;
    // if(this.props.tid==="882f5816-dc41-4e2f-913b-96dd26b46375") {
    //   filePath = {stix_path: "/mng/results/capstone-results/smoketest.cap"}
    // } else {
    //   filePath = {stix_path: stixPath}
    // }

    let filePath = {stix_path: stixPath}

    // console.log("stix path: ", stixPath)

    var isHashLoaded = false;
    var notificationData = [];
    var mitigationData = [];
    var fileInfoData = {};
    var urlInfoData = {};
    var antivirusData = [];
    var peMetadataTemp = [];
    var dropFileTemp = [];
    var attackPatternTemp = [];
    var attackPatternTagTemp = [];
    var behaviorAnalysisTemp = [];
    var networkAnalysisTemp = [];
    var httpDataTemp = {};
    var urlAnalysisTemp = {};

    fetch(apiUrl, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        'Authorization': 'Bearer ' + keycloak.token
      },
      body: JSON.stringify(filePath),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        // console.log('TAGS --> network:    ' + responseJson.results[0].stix.network);
        
        responseJson.results.map((data) => {
          // console.log("results of getMifrReport: ", data)

          if (data.canned[NOTIFICATION].length > 0) {
            notificationData = data.canned[NOTIFICATION].join(" ");
          }

          if (data.canned[MITIGATION].length > 0) {
            mitigationData = data.canned[MITIGATION];
          }

          if (data.stix['Tlp'].length > 0) {
            if (data.stix['Tlp'][0].length > 0) {
              this.setState({ tlp: data.stix['Tlp'][0][0]['name'] });
            }
          }

          if (data.stix['File name and TID'].length > 0) {
            this.setState({ fileName: data.stix['File name and TID'][0]['name']});
          }
 
          data.stix[FILEINFO].map((fileData) => {
            if (fileData["MD5"] && !isHashLoaded) {
              
              fileInfoData = fileData;
              isHashLoaded = true;
            }  
          });

          antivirusData = data.stix[ANTIVIRUS];

          this.getAntivirusSummary(antivirusData);

          // PE Metadata (.dll, .exe)

          if (data.stix['PE File'] && data.stix['PE File'].length > 0) {
            if (data.stix['PE File'][0][0] && data.stix['PE File'][0][0].length > 0) {
              let nameAndSizeData = data.stix['PE File'][0][0];

              nameAndSizeData.map(data => {
                let name = "";
                let md5="";
                let size ="";
                let entropy="";

                if (data['name']) {
                  name = data['name'];

                  // if name includes backslash, remove everything after backslash, including backslash
                  name = name.replace(/\\.*$/g, "");
                 } 

                if (data['hashes'] && data['hashes']['MD5']) {
                  md5 = data['hashes']['MD5'];
                }

                if (data['size'] >= 0) {
                  size = data['size'];
                }

                if (data['entropy'] >= 0) {
                  entropy = data['entropy'];
                }
                let exeMetadataObj = new ExeMetaDataModel(name, md5, size, entropy);
                peMetadataTemp.push(exeMetadataObj);
              })
            }

            if(data.stix['PE File'][0][1] && data.stix['PE File'][0][1].length > 0) {
              let md5AndEntropyData = data.stix['PE File'][0][1];
              
              for(let i=0; i<peMetadataTemp.length; i++) {
                let matchFound = md5AndEntropyData.find(item => item.name === peMetadataTemp[i].name);
                let MD5="";
                let entropy="";

                if(matchFound) {
                  if (matchFound['hashes'] && matchFound['hashes']['MD5']) {
                    MD5 = matchFound['hashes']['MD5'];
                  }
                  if (matchFound['entropy'] >= 0) {
                    entropy = matchFound['entropy'];
                  }
                }
                peMetadataTemp[i].setMD5(MD5);
                peMetadataTemp[i].setEntropy(entropy);
              }
            }
          }

          // PDF Metadata (.pdf)
            
          if ((!data.stix['PE File'] || data.stix['PE File'].length===0) && data.stix['Extensions'].length > 0) {
            
            // if (data.stix['Extensions'][0]['windows-pebinary-ext']) {
          
            //   let exeMetadata = data.stix['Extensions'][0]['windows-pebinary-ext']['sections'];
 
            //   exeMetadata.map((data) => {
                 
            //      let name = "";
            //      let md5 = "";
            //      let size = "";
            //      let entropy = "";

            //      if (data['name']) {
            //        name = data['name'];
            //      } 
                 
            //      if (data['hashes'] && data['hashes']['MD5']) {
            //        md5 = data['hashes']['MD5'];
            //      }
                 
            //      if (data['size']) {
            //        size = data['size'];
            //      }

            //      if (data['entropy']) {
            //        entropy = data['entropy'];
            //      }

            //      let exeMetadataObj = new ExeMetaDataModel(name,md5,size,entropy);
            //      peMetadataTemp.push(exeMetadataObj);

            //   });
            // }

            if (data.stix['Extensions'][0]['pdf-ext']) {
              
              let pdfData = data.stix['Extensions'][0]['pdf-ext']['document_info_dict'];
              this.setState({isPdf: true});
              
              let author = pdfData['Author'];
              let title = pdfData['Title'];
              let producer = pdfData['Producer'];
              let creationDate = pdfData['CreationDate'];
              let modificationDate =pdfData['ModDate'];

              let pdfMetaDataModel = new PdfMetaDataModel(author,title,
                producer,creationDate,modificationDate);

              peMetadataTemp.push(pdfMetaDataModel);

            }
          }  
          
          if (data.stix['Drops'].length > 0) {     

            let response = null;

            const apiUrl = apiConnection.getTranMetaListUrl();
            const body = {
               arg_name: "drop_file_pid",
               arg_val: this.state.tid,
              //  drop_file_pid: this.state.tid,
               limit: "1000",
               order_by: "file_size",
               file_metadata: true
            }     
            
              response = fetch(apiUrl, {
                method: 'PUT',
                headers: {
                  "Content-Type": "application/json", 
                  'Authorization': 'Bearer ' + keycloak.token
                },
                body: JSON.stringify(body),
            
              })
              .then((response) => response.json())
              .then((responseJson) => {
                if (responseJson['transaction_files'].length > 0) {
                  
                  let fileData = responseJson['transaction_files'];

                  fileData.map((data) =>      
                    Object.keys(data).map((key) => {
                      
                      // let TID = key;
                      // let sha1 = data[key]['file_metadata']['sha1'];
                      // let name = data[key]['transaction_file_data']['original_file_name']
                      const dropFileObj = {
                        tid: key,
                        sha1: data[key]['file_metadata']['sha1'],
                        fileName: data[key]['transaction_file_data']['original_file_name'],
                        verdict: data[key]['transaction_file_data']['threat_score']
                      }

                      // let dropFileObj = new DropFileModel(dropFileObj);
                      dropFileTemp.push(new DropFileModel(dropFileObj));
                    }))
                  this.setState({dropFile: dropFileTemp});
               }
              })
            .catch( (error) => {
              console.error("Error calling: " + apiUrl, error);
            })
          }
          else {
            this.setState({dropFile: dropFileTemp});
          }
        
          if (data.stix['Yara Indicators'].length > 0) {
            if (data.stix['Yara Indicators'][0].length > 0) {
              if (data.stix['Yara Indicators'][0][0]['x_ncas_cisa_gov_yara_matches']) {      
        
                var yaraData = data.stix['Yara Indicators'][0][0]['x_ncas_cisa_gov_yara_matches'];
                var yaraDataTemp = [];

                yaraData.map((data) => {
                  yaraDataTemp.push(data.meta);
                });

                this.setState({yara: yaraData});
                

                this.setState({yara: yaraDataTemp});
              }
            }
          }
          if (data.stix['Attack Pattern'].length > 0 && data.stix['Attack Pattern'][0].length > 0) {
              let attackPatternData = data.stix['Attack Pattern'][0];
              this.setState({attackPattern: attackPatternData});
          } else {
            this.setState({attackPattern: []});
          }



          // let attackPatternTagNew = [];
          const apiUrl = apiConnection.getTranTagListUrl();
          const body = {
            "tid": this.props.tid
          };     
         
          fetch(apiUrl, { 
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              'Authorization': 'Bearer ' + keycloak.token
            },
            body: JSON.stringify(body),
          })
          .then(response => response.json())
          .then(responseJson => {
              if (responseJson.results.length > 0) {
                // console.log('TAGS -if-> network:    ', responseJson);
                responseJson.results.map((attackPatternItem) => {
                  let name = attackPatternItem['tag_name'];
                  let description = attackPatternItem['tag_value'];
                  let buttonColor = attackPatternItem['category_color_cd'];
                  let attackPatternObj = new AttackPatternTagModel(name, description, buttonColor);
                  //>>>>>>>>>>>>>>>  Code modified begins to show multiple yara tag. JIRA ticket 2421.  >>>>>>>>>>>>>>>>>   
                  if (name === "YARA") {
                    let tag_name = "";
                    let tag_description = "";
                    let tag_text = "";
                    if (Array.isArray(description)) {
                      for (let i=0; i<description.length; i++ ) {
                        tag_name = description[i].rule;
                        tag_description = description[i].tags;
                        tag_text = "";
                        for (let ii=0; ii<tag_description.length; ii++) {
                          if (ii===0) {
                            tag_text = tag_description[ii];
                          } else {
                            tag_text = tag_text + ", " + tag_description[ii]; 
                          }
                        }
                        let attackPatternObj = new AttackPatternTagModel("YARA: " + tag_name, tag_text, buttonColor);
                        attackPatternTagTemp.push(attackPatternObj);
                      }
                    } else {
                      attackPatternTagTemp.push(attackPatternObj);
                    }
                  } else {
                    attackPatternTagTemp.push(attackPatternObj);
                  }
                  //<<<<<<<<<<<<<<  Code modified ends to show multiple yara tag. JIRA ticket 2421.    <<<<<<<<<<<<<<<<<< 
                  if (Array.isArray(description)) {
                    attackPatternObj.description = attackPatternObj.description[0].description;
                    // attackPatternObj.description = description.join(", ");
                  }
                  if (attackPatternObj.description == null || attackPatternObj.description == "") {
                    attackPatternObj.description = attackPatternObj.name;
                  }
                  if (typeof attackPatternObj.description == 'object') {   
                    attackPatternObj.description = attackPatternObj.description[0].description;
                  }
                  this.setState({attackPatternTag: attackPatternTagTemp });
                })
              } else {
                // console.log('TAGS -else-> network:    ' + JSON.stringify(responseJson.results));
                this.setState({attackPatternTag: ["No TAG found."]});
              }
          })
          .catch( (err) => {
            console.error('Error:   ' + err);
          })

          if (data.stix['Behavior Analysis'].length > 0) {
         
            let behaviorAnalysis = data.stix['Behavior Analysis'];

            behaviorAnalysis.map((behaviorAnalysisData) => {
              behaviorAnalysisData.map((behaviorAnalysisDataObj) => {
                behaviorAnalysisDataObj.map((data) => {

                  var key = data['Name'];
                  var value = '';
                     
                  if (key == 'NtCreateFile') {
                    value = data['Values']['filepath'];
                  }  
                  else {
                    value = data['values']['regkey'];  
                  }
  
                  var behaviorData = {
                    name: key,
                    path: value
                  }

                  behaviorAnalysisTemp.push(behaviorData);
                })
              })
            })         
          }
          
          this.setState({behaviorAnalysis: behaviorAnalysisTemp});
       
          if (data.stix['Domain name'].length > 0) {
         
            let domainName = data.stix['Domain name'];

            domainName.map((domainNameData) => {
              domainNameData.map((data) => {
                
                var networkActivityData = {
                  type: data.type,
                  value: data.value
                }

                networkAnalysisTemp.push(networkActivityData);
              })

            })     
          }

          if (data.stix['ipv4 addr'].length > 0) {
         
            let ipv4Addr = data.stix['ipv4 addr'];

            ipv4Addr.map((ipv4AddrData) => {
              ipv4AddrData.map((data) => {
                
                var networkActivityData = {
                  type: data.type,
                  value: data.value
                }

                networkAnalysisTemp.push(networkActivityData);
              })

            })     
          }

          if(this.props.currentTinfo.fileType==="URL") {
            this.setState({isUrl: true});
            this.props.isUrl(true);
          }

          if (data.stix['URL Submissions'] && data.stix['URL Submissions'].length > 0) {
            // assign URL data for URL Analysis section
            if(this.props.currentTinfo.fileType==="URL") {
              urlInfoData = data.stix['URL Submissions'][0].find(element => element.MD5);
              urlAnalysisTemp = data.stix['URL Submissions'];
            }
            // assign HTTP/HTTPs data for Network Activity section
            else {
              httpDataTemp = data.stix['URL Submissions'];
            }
          } 
        });

        this.setState({
          notification: notificationData,
          mitigation: mitigationData,
          fileInfo: fileInfoData,
          urlInfo: urlInfoData,
          antivirus: antivirusData,
          metadata: peMetadataTemp,
          attackPatternTag: attackPatternTagTemp,
          urlAnalysis: urlAnalysisTemp,
          networkAnalysis: networkAnalysisTemp,
          httpDataForNetworkAnalysis: httpDataTemp
        });        
      })
      .catch((error) => {
        console.error("MIFR Report Error...: ", error);
      })
  }

  displayModal (index) {
    this.setState({
      showVMRayScreenshotModal: true,
      indexOfClickedImage: index
    });
  }

  /**
   *  Render Notification Component
   */
  renderNotification() {
    return (
      <div className={"notification-container"}>
        <h4>{NOTIFICATION}</h4>
        <p>{this.state.notification}</p>
      </div>
    );
  }

  /**
   *  Render Mitigation Recommendations
   */
  renderMitigation() {
    return (
      <div className="section-container" id="mitigation-recommendations">
        <MifrSectionHeader
          mitigation={MITIGATION}
          sectionId="Mitigation Recommendations"
          open={this.state.mitigationOpen}
          handleClick={() => this.setState({mitigationOpen: !this.state.mitigationOpen})}
        />
        <Collapse in={this.state.mitigationOpen} timeout="auto" unmountOnExit style={{padding: "13px 20px 13px 5px"}}>
          <div className="mitigation-sub-title">
            <strong><p>{this.state.mitigation[0]}</p></strong>
          </div>
            <div className="mitigation-data-container">
               <ol>
               {this.state.mitigation.slice(1).map(((mitigationData, i) => {
                  return (
                      <li key={i}>{mitigationData}</li>
                  );
               }))}
               </ol>
            </div>
        </Collapse>
      </div>
    );
  }

  /**
   * Render summary section of the report
   */
  renderSummary() {
    
    const FILENAME = "name";
    const ID = "id";
    const MIME_TYPE = "mime_type";
    const SHA1 = "SHA-1";
    const MD5 = "MD5";
    const SHA256 = "SHA-256";
    const SIZE = "size";

    const urlSummaryFields = [
      {"key": "URL", "val": this.state.urlAnalysis['value'] || this.props.currentTinfo.fileNameOrUrl}, 
      {"key": "AV Summary", "val": this.state.avSummary},
      {"key": "MD5", "val": this.state.urlInfo ? this.state.urlInfo[MD5] : ""},
      {"key": "SHA1", "val": this.state.urlInfo ? this.state.urlInfo[SHA1] : ""},
      {"key": "SHA256", "val": this.state.urlInfo ? this.state.urlInfo[SHA256] : ""},
      {"key": "TAGS"}
    ]

    const fileSummaryFields = [
      {"key": "Filename", "val": this.state.fileName}, 
      {"key": "File Type", "val": this.state.fileInfo[MIME_TYPE]},
      {"key": "File Size", "val": this.state.fileInfo[SIZE] ? this.state.fileInfo[SIZE]+" bytes" : ""},
      {"key": "AV Summary", "val": this.state.avSummary},
      {"key": "MD5", "val": this.state.fileInfo[MD5]},
      {"key": "SHA1", "val": this.state.fileInfo[SHA1]},
      {"key": "SHA256", "val": this.state.fileInfo[SHA256]},
      {"key": "TAGS"}
    ]

    let summaryFields = this.state.isUrl ? urlSummaryFields : fileSummaryFields;

    return (
      <div className="section-container">
        <MifrSectionHeader
          sectionId="Summary"
          open={this.state.summaryOpen}
          handleClick={() => this.setState({summaryOpen: !this.state.summaryOpen})}
        />
        <Collapse in={this.state.printing ? true : this.state.summaryOpen} timeout="auto" unmountOnExit>
            <div className="subtitle-container">
              <h5>{this.state.isUrl ? "Submitted URL" : "Submitted File"}</h5>
            </div>
            <table className="summary-table">
              <tbody>
                {summaryFields.map( (summaryField, ind) => 
                      <tr key={ind}>
                        <td className="summary-key">{summaryField.key}</td>
                        <td className="summary-val">
                          {
                            summaryField.key==="TAGS"
                            
                            ?

                            this.state.attackPatternTag.length>0 && this.state.attackPatternTag[0]!="No TAG found." &&
                              <div className="mifr-tags-section">
                                {this.state.attackPatternTag.map((attackDataOne => {
                                  return (
                                    <AttackPatternTag
                                      key={attackDataOne.getName()} 
                                      name={attackDataOne.getName()} 
                                      buttonColor={attackDataOne.getButtonColor()} 
                                      description={attackDataOne.description}
                                    />                 
                                  );
                                }))}
                              </div>

                            : 
                              
                            summaryField.val
                          }
                        </td>
                      </tr>
                    )
                } 
              </tbody>
            </table> 

          <div className="subtitle-container">
            <h5>Dropped Files</h5>
          </div>

          <div className="data-container">
            <table style={{tableLayout: "fixed"}}>
              <tbody>
                <tr className="mifr-dropped-file-headers">
                  <th width="25%">TID</th>
                  <th width="30%">SHA1</th>
                  <th width="30%">File Name</th>
                  <th style={{textAlign: "center"}}>Verdict</th>
                </tr>
                {this.state.dropFile.map( dropFile => {
                  return (
                    <DroppedFile 
                      key={dropFile.getTID()} 
                      TID={dropFile.getTID()}
                      Sha1={dropFile.getSha1()} 
                      fileName={dropFile.getFileName()}
                      verdict={dropFile.getVerdict()} 
                      update={mifrData=>this.update(mifrData)}
                    />
                  );
                })} 
              </tbody>        
            </table>  
          </div>

        </Collapse>
      </div>
    ); 
  } 

  getAntivirusSummary(antivirusData) {
    const totalEngineCount = antivirusData[0].length;

    const noThreatEngines = antivirusData[0].filter( engine => {
      return engine.threat_found.includes("Unavailable") || engine.threat_found==="No Threat Found"
    })

    const noThreatEngineCount = noThreatEngines.length;
    const threatEngineCount = totalEngineCount - noThreatEngineCount;
    this.setState({avSummary: threatEngineCount + "/" + totalEngineCount})
  }

  /*
   * Renders Static Analysis section
   */
  renderStaticAnalysis() {
    
    let metadata = null;

    // Conditional Rendering depends on if the metadata is PDF or EXE file. 
    if (this.state.isPdf) {
      metadata =
      <React.Fragment>
        <div className="subtitle-container">
          <h5>Metadata PDF</h5>
        </div>
        <div className="data-container">
            
            {this.state.metadata.map(((metadata, i) => {
                return (
                  <ul key={i}>
                    <li>Author: {metadata.getAuthor()}</li><hr/>
                    <li>Title: {metadata.getTitle()}</li><hr/>
                    <li>Producer: {metadata.getProducer()}</li><hr/>
                    <li>Creation Date: {metadata.getCreationDate()}</li><hr/>
                    <li>Modification Date: {metadata.getModificationDate()}</li><hr/>
                  </ul>
                );
             }))}
   
        </div>
      </React.Fragment>
      
    } else {
      metadata =
      <React.Fragment>
        <div className="subtitle-container">
          <h5>Metadata PE</h5>
        </div>
        <div className="data-container">
          <table>
            <tbody>
              <tr>
                <th width="15%">Name</th>
                <th width="30%">MD5</th>
                <th width="20%">Raw Size (Bytes)</th>
                <th width="20%">Entropy</th>
              </tr>
              {this.state.metadata.map((metadata, ind) => {
                  return (
                    <tr key={ind}>
                      <td>{metadata.getName()}</td>
                      <td>{metadata.getMD5()}</td>
                      <td>{metadata.getSize()}</td>
                      <td>{metadata.getEntropy()}</td>
                    </tr>
                  );
              })}
            </tbody>
          </table>  
        </div>
      </React.Fragment>    
    }

    return (
      <div className="section-container">
        <MifrSectionHeader
          sectionId="Static Analysis"
          open={this.state.staticAnalysisOpen}
          handleClick={() => this.setState({staticAnalysisOpen: !this.state.staticAnalysisOpen})}
        />
        <Collapse in={this.state.staticAnalysisOpen} timeout="auto" unmountOnExit>
          {metadata}
          <div className="subtitle-container">
            <h5>Antivirus</h5>
          </div>
          <div className="data-container">
            <table>
              <tbody>
                <tr>
                  <th>Engine</th>
                  <th>Signature</th>
                  <th>Last Signature Update (Z)</th>
                </tr>
                {this.state.antivirus.map( (antivirus, i) => {
                  return (
                    <React.Fragment key={i}>
                      {antivirus.map ((data => {
                        return (
                          <tr key={data.name}>
                            <td>{data['name']}</td>
                            <td>{data['threat_found']}</td>
                            <td>{data['def_time']}</td>
                          </tr>
                        );
                      }))}
                    </React.Fragment>
                  );
                })} 
              </tbody>        
            </table>  
          </div>
          <div className="subtitle-container">
            <h5>YARA Rules</h5>
          </div>
          <div className="data-container">
            <table>
              <tbody>
                <tr>
                  <th>Actor</th>  
                  <th>Category</th>
                  <th>Family</th>
                  <th width="45%">Description</th>
                </tr>
                {this.state.yara.map(((yara, i) => {
                  return (
                    <tr key={i}>
                      <td>{yara.Actor==="n/a" ? "N/A" : yara.Actor}</td>
                      <td>{yara.Category==="n/a" ? "N/A" : yara.Category}</td>
                      <td>{yara.Family==="n/a" ? "N/A" : yara.Family}</td>
                      <td>{yara.description}</td>
                    </tr>  
                  );
                }))}
              </tbody>
            </table>  
          </div>
        </Collapse>
      </div>
    ); 
  }

  handleSelect = (selectedIndex) => {
    this.setState({indexOfClickedImage: selectedIndex})
  }

  downloadVMRayScreenshots = () => {
    const url = apiConnection.downloadVMRayScreenshots();
    const header = { 
      "Content-Type": "application/json",
      'Authorization': 'Bearer ' + keycloak.token
    }
    const method = "PUT";
    const data_in = {tid: [this.props.tid]}
    
    httpCalls
      .configureAxiosRequestDownload(
        url,
        header,
        method,
        data_in
      )
      .then(res => {
        if (res.data) {
          const downloadUrl = window.URL.createObjectURL(new Blob([res.data], {type: "application/zip"}));
          const link = document.createElement('a');
          link.href = downloadUrl;
          const zipFileName = "VMRay_Screenshots_" + this.props.tid + '.zip';
          link.setAttribute('download', zipFileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
      })
      .catch(err => {
        console.error("Error with downloading VMRay screenshots: " + err);
      });
  }

  getVMRayScreenshots = () => {
    const input= {tid: [this.props.tid]}
    
    fetch(apiConnection.getVMRayScreenshots(), {
        method: "PUT",
        headers: { 
          "Content-Type": "application/json",
          'Authorization': 'Bearer ' + keycloak.token
        },
        body: JSON.stringify(input),
    })
    .then(res => res.json())
    .then(resJson => {
      if(resJson && (resJson.errors.length > 0 || resJson.messages.length > 0)){
          if(resJson.messages[0].includes("No screenshots exist for tid")) {
          }
        }else{ 
          const screenshotData = resJson.results;
          this.setState({screenshotData: screenshotData});
        }
    })
    .catch(error => {
        console.error("Error in getting VMray screenshots: ", error);
    }); 
  }

  /**
   * Renders Dynamic Analysis section
   */
  renderDynamicAnalysis() {
    const screenshotCards = this.state.screenshotData;
    
    return (
      <div className="section-container">
        <MifrSectionHeader
          sectionId="Dynamic Analysis"
          open={this.state.dynamicAnalysisOpen}
          handleClick={() => this.setState({dynamicAnalysisOpen: !this.state.dynamicAnalysisOpen})}
        />
        <Collapse in={this.state.dynamicAnalysisOpen} timeout="auto" unmountOnExit>
          <div className="subtitle-container">
            <h5>File Activity & Registry Modifications</h5>
          </div>
          <div className="data-container">
            <table>
              <tbody>
                <tr>
                  <th>Key/Value</th>
                  <th>Description</th>
                </tr>
                {this.state.behaviorAnalysis.map(((data, i) => {
                  return (
                    <tr key={i}>
                      <td className="dynamic-analysis-key-value">{data.path}</td>
                      <td>{data.name}</td>
                    </tr>  
                  );
                }))}
              </tbody>        
            </table>  
          </div>

          {screenshotCards.length>0 &&

          <div>
            <div className="subtitle-container" style={{position: "relative"}}>
              <h5>VMRay Screenshots</h5>
              <Button 
                style={{position: "absolute", top: "8px", right: "20px"}}
                onClick={()=>this.downloadVMRayScreenshots()}
                id="vmray-screenshots-download-all-btn"
              >
                Download All
              </Button>
            </div>
            <div className="data-container">
              <Row style={{margin: "5px"}}>
                {screenshotCards.map( (card, index) => {
                  let decodedImage = "data:image/png;base64,"+card.file_data;
                  return (
                    <div key={index}>
                      <Card id="vmray-screenshot-card">
                        <Card.Img 
                          className="vmray-screenshot-image"
                          id={"vmray-screenshot-"+index} 
                          variant="top" 
                          src={decodedImage}
                          style={{cursor: "pointer", height: "150px", borderRadius: "calc(.25rem - 1px)"}} 
                          onClick={()=>this.displayModal(index)}
                        />
                      </Card>
                      <VMRayScreenshotModal
                        showModal={this.state.showVMRayScreenshotModal}
                        hideModal={()=>this.setState({showVMRayScreenshotModal: false})}
                        clickedIndex={this.state.indexOfClickedImage}
                        images={screenshotCards}
                        handleSelect={this.handleSelect}
                      />
                    </div>
                  ) 
                })}
              </Row>
            </div>
          </div>
          }

          

        </Collapse>
      </div>
    );  
  }

  /**
   * Renders Network Activity section
   */
  renderNetworkActivity() {
    let httpData = this.state.httpDataForNetworkAnalysis[0];
    
    return (
      <div className="section-container">
        <MifrSectionHeader
          sectionId="Network Activity"
          open={this.state.networkActivityOpen}
          handleClick={() => this.setState({networkActivityOpen: !this.state.networkActivityOpen})}
        />
        <Collapse in={this.state.networkActivityOpen} timeout="auto" unmountOnExit id="network-activity-section">
          {/* <div className="data-container">
             <ul>
               <li>DNS</li><hr/>
               <li>HTTP/HTTPS</li><hr/>
               <li>Other TCP/IP, UDP Traffic</li><hr/>
             </ul>
          </div> */}
          {((this.state.networkAnalysis && this.state.networkAnalysis.length > 0) || 
            (httpData && httpData.length > 0))
            ?
            <div>
              <table className="data-container">
                <tbody>
                  <tr>
                    <th>Value</th>
                    <th>Type</th>
                  </tr>
                  {this.state.networkAnalysis.map(((data, i) => {
                    return (
                      <tr key={i}>
                        <td>{data.value}</td>
                        <td>{data.type}</td>
                      </tr>  
                    );
                  }))}
                </tbody>        
              </table>
              {httpData && httpData.length > 0 &&
                <>
                  <div className="subtitle-container">
                    <h5>HTTP/HTTPS Protocol</h5>
                  </div>  
                  <div style={{margin: "20px auto 40px auto"}}>
                    {httpData.map( (urlObject, index) => {
                      return (
                        <>
                          <div key={index} style={{margin: "10px auto"}}>                  
                            <table className="url-analysis-table">
                              <tbody>
                                {Object.entries(urlObject).map( ([key, val], ind) => {
                                    return (
                                      <tr key={ind}>
                                        <td className="url-analysis-key">{this.handleURLAnalysisKeys(key)}</td>
                                        <td className="url-analysis-val">{val}</td>
                                      </tr>
                                    )
                                })}
                              </tbody>
                            </table> 
                          </div>
                          <hr width="91.5%"/>
                        </>
                      );  
                    })}
                  </div>
                </>
              }
            </div>
            : 
            <div className="empty-ear-section">
              No network activity detected.
              <hr />
            </div>
          }
        </Collapse>
      </div>
    ); 
  } 

  /**
   * Render URL Analysis section
   */
   renderURLAnalysis() {
    let urlData = this.state.urlAnalysis[0];

    return (
      <div className="section-container">
        <MifrSectionHeader
          sectionId="URL Analysis"
          open={this.state.urlAnalysisOpen}
          handleClick={() => this.setState({urlAnalysisOpen: !this.state.urlAnalysisOpen})}
        />   
        <Collapse in={this.state.urlAnalysisOpen} timeout="auto" unmountOnExit id="url-analysis-section"network>
          { (urlData && urlData.length > 0) 
            ? 
              urlData.map( (urlObject, index) => {
                return (
                  <>
                    <div key={index} style={{margin: "10px auto"}}>                  
                      <table className="url-analysis-table">
                        <tbody>
                          {Object.entries(urlObject).map( ([key, val], ind) => {
                              return (
                                <tr key={ind}>
                                  <td className="url-analysis-key">{this.handleURLAnalysisKeys(key)}</td>
                                  <td className="url-analysis-val">{val}</td>
                                </tr>
                              )
                          })}
                        </tbody>
                      </table> 
                        
                    </div>
                    <hr width="91.5%"/>
                  </>
                );  
              })
            : 
              <div className="empty-ear-section">
                No URL analysis detected.
                <hr />
              </div>
          }
        </Collapse>
      </div>
    )
  } 

  /**
   * Renders Mitre Characterization section
   * 
   */

  handleMitreValues(key, val) {

    // array?
    if( Array.isArray(val) )  {

      // array of strings?
      if( typeof val[0] == 'string' ) {
        return (
          val.map( (item, ind, arr) => {
            if(arr.length-1 == ind) {
              return <span key={ind}>{item}</span>
            } else {
              return <span key={ind}>{item}, </span>
            }
          })
        )
      } 

      // array of objects?
      else { 
          if((key == "kill_chain_phases" || key == "techniques") && val.length > 0) {
            return (
              <table className="mitre-sub-table">
                <tbody>
                  <tr>
                    <th>Kill Chain Name</th>
                    <th>Phase Name</th>
                  </tr>
                  {val.map( (item, i) => {
                    return (
                      <tr key={i}>
                        {Object.values(item).map( (dataItem, ind) => {
                          return <td key={ind}>{dataItem}</td>
                        })}
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            )
          } else if((key == "external_references" || key == "tactics") && val.length > 0) {
              return (
                <table className="mitre-sub-table">
                  <tbody>

                    <tr>
                      <th>Description</th>
                      <th>External ID</th>
                      <th>Source Name</th>
                      <th>URL</th>
                    </tr>

                    {val.map( (item, i) => {

                      if(item.external_id) {
                        return (
                          <tr key={i}>
                            <td>N/A</td>
                            {Object.values(item).map( (dataItem, ind) => {
                                return <td key={ind}>{dataItem}</td>;
                            })}
                          </tr>
                        )
                      }

                      else if(item.description) {
                        return (
                          <tr key={i}>
                            <td>{item.description}</td>
                            <td>N/A</td>
                            <td>{item.source_name}</td>
                            <td>{item.url}</td>
                          </tr>
                        )
                      }

                    })}
                  </tbody>
                </table>
              )
          } else{
            return "";
          }
      } 
    } else {
        if(typeof val == 'string' || typeof val == 'boolean') {
          return val.toString();
        } else {
          return 'object';
        }
    }
  }

  handleMitreKeys(key) {
    key = key.replaceAll('_', ' ');
    key = key.replace(/(^\w{1})|(\s+\w{1})/g, char => char.toUpperCase());
    key = key.replaceAll("Mitre", "MITRE");
    key = key.replaceAll("Id", "ID");
    key = key.replace("External References", "Tactics");
    key = key.replace("Kill Chain Phases", "Techniques");
    return key;
  }

  handleURLAnalysisKeys(key) {
    key = key.replaceAll('_', ' ');
    key = key.replace(/(^\w{1})|(\s+\w{1})/g, char => char.toUpperCase());
    key = key.replaceAll("Id", "ID");
    key = key.replaceAll("X Ncas Cisa Gov Vmray Verdict", "X NCAS CISA.gov VMRay Verdict");
    return key;
  }

  renderMitreCharacterization() {

    return (
      <div className="section-container">
        <MifrSectionHeader
          sectionId="MITRE ATT&CK Characterization"
          open={this.state.mitreCharacterizationOpen}
          handleClick={() => this.setState({mitreCharacterizationOpen: !this.state.mitreCharacterizationOpen})}
        />   
        <Collapse in={this.state.mitreCharacterizationOpen} timeout="auto" unmountOnExit>
          { (this.state.attackPattern && this.state.attackPattern.length > 0) 
            ? 
              this.state.attackPattern.map( (attackData, indx) => {
                return (
                  <div key={indx}>                  
                    <div className="subtitle-container">
                      <h5>{attackData.name}</h5>
                    </div>
                    <table className="mitre-table">
                      <tbody>
                        {Object.entries(attackData).map( ([key, val], ind) => {
                            return (
                              <tr key={ind}>
                                <td className="mitre-key">{this.handleMitreKeys(key)}</td>
                                <td className="mitre-val">{this.handleMitreValues(key, val)}</td>
                              </tr>
                            )
                        })}
                      </tbody>
                    </table> 
                  </div>
                );
              })

            : 
              <div className="empty-ear-section">
                No ATT&CK findings detected.
                <hr />
              </div>
          }
        </Collapse>
      </div>
    ); 
  }  

  handleBreadcrumbClick(TID, stixPath, index) {
    var historyTemp = this.state.history;
    historyTemp.length = index;

    this.setState({tid: TID, history: historyTemp});
    this.setup(stixPath);
  }

  renderBreadcrumbs() {
    if (this.state.history.length > 0) {
      return(      
        <div className="breadcrumb-container">   
          <Breadcrumb>
            {this.state.history.map((data, index) => {
               return(           
               <Breadcrumb.Item
                   key={index}
                   onClick={ () => this.handleBreadcrumbClick(data.tid, data.stixPath, index)}
               >{data.tid}</Breadcrumb.Item>
               )}
             )
            }      
          </Breadcrumb> 
        </div>
      )  
    }
  }

  validateTlp = () => {
    const tlp = this.state.tlp;
    const regExp = /TLP:(?:GREEN|RED|CLEAR|AMBER|AMBER\+STRICT)$/;
    if (regExp.test(tlp) == true) {
      return tlp;
    }
    return null;
  }

  validateTid = () => {
    if (validateUUID(this.state.tid)) return this.state.tid;
    return null;
  }

  handleExpandAll = () => {
    this.setState({
      summaryOpen: true, 
      staticAnalysisOpen: true, 
      dynamicAnalysisOpen: true,
      networkActivityOpen: true,
      urlAnalysisOpen: true,
      mitreCharacterizationOpen: true,
      mitigationOpen: true
    })
  }

  handleCollapseAll = () => {
    this.setState({
      summaryOpen: false, 
      staticAnalysisOpen: false, 
      dynamicAnalysisOpen: false,
      networkActivityOpen: false,
      urlAnalysisOpen: false,
      mitreCharacterizationOpen: false,
      mitigationOpen: false
    })
  }

  handleMSISACAssistanceRequest = () => {
    let hrefValue="mailto:" + process.env.REACT_APP_MS_ISAC_EMAIL;
    hrefValue += "?subject=" + this.props.tid;
    hrefValue += "&body=Dear MS-ISAC Team,";
    // console.log("href=" + hrefValue);
    return hrefValue;
  }

  /**
   * Render MIFR report
   */
  render() {
    const tid = this.validateTid();
    const tlp = this.validateTlp();

    return (
      <div>
        <div style={{position: 'relative'}} id="mifr-top-icons">
          <Icon
            name="caret square up" title="Collapse All"
            link className="mifr-collapse-all-icon" size="large"
            onClick={()=>this.handleCollapseAll()}
          />
          <Icon
            name="caret square down" title="Expand All"
            link className="mifr-expand-all-icon" size="large"
            onClick={()=>this.handleExpandAll()}
          />
          <PrintIcon
            className="mifr-print-icon" onClick={()=>window.print()}
            style={{fontSize: 23}}
          />
          <FileDownloadRoundedIcon 
            onClick={()=>this.setupHTMLDownload()}
            className="mifr-html-icon" 
          />
        </div>

        {hasMSISACAccess() &&
          <Button 
            size="sm" 
            id="analysis-help-btn" 
            href={this.handleMSISACAssistanceRequest()}
            style={{ right: '87px'}}>
            <Icon name='mail'/> Analysis Help
          </Button>
        }
        
        <div className="mifr-container gray-font" id="mifr-report-page">
          {this.renderBreadcrumbs()}
          
          <div>
            <table className="title" style={{width: "100%"}}>
              <tbody>
                <tr>
                  <td id="cisa-logo-mifr-html" width="6%">
                    <img id="cisa-logo-html" src={CISA_LOGO} alt="cisa_logo"/>
                  </td>
                  <td width="80%">
                    <h1>Malware Initial Findings Report (MIFR)</h1>
                    <h4>{tid}</h4>
                  </td>
                  <td width="10%" style={{textAlign: "right"}}>
                    <h2>{tlp}</h2>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {this.renderNotification()} 
          {this.renderSummary()}
          {this.renderStaticAnalysis()}
          {this.renderDynamicAnalysis()}
          {this.state.isUrl ? this.renderURLAnalysis() : this.renderNetworkActivity()}
          {this.renderMitreCharacterization()}
          {this.renderMitigation()}
        </div>
      </div>
    );
     
  } 
}
const mapStateToProps = state => {
  return {
      currentTinfo: state.analysisReducer.currentTinfo
  }
}

export default connect(mapStateToProps, null)(MifrReport);
