export const SubmissionTableHeaderList = [
    {
        id: 'created_ts',
        label: 'Submitted'
    },
    {
        id: 'sid',
        label: 'SID'
    },
    {
        id: 'file_cnt',
        label: 'Submission Count'
    },
    // {
    //     id: 'first_name',
    //     label: 'First Name'
    // },
    // {
    //     id: 'last_name',
    //     label: 'Last Name'
    // },
    {
        id: 'organization',
        label: 'Organization'
    },
    {
        id: 'submission_status_cd',
        label: 'Status'
    },
    // {
    //     id: 'user_id',
    //     label: 'User'
    // },
    {
        id: 'traffic_light_protocol_cd',
        label: 'TLP'
    }
]

export const SubmissionTableHeaderListMngCisaAnalyst = [
    {
        id: 'created_ts',
        label: 'Submitted'
    },
    {
        id: 'sid',
        label: 'SID'
    },
    {
        id: 'file_cnt',
        label: 'Submission Count'
    },
    {
        id: 'first_name',
        label: 'First Name'
    },
    {
        id: 'last_name',
        label: 'Last Name'
    },
    {
        id: 'organization',
        label: 'Organization'
    },
    {
        id: 'submission_status_cd',
        label: 'Status'
    },
    {
        id: 'user_id',
        label: 'User'
    },
    {
        id: 'traffic_light_protocol_cd',
        label: 'TLP'
    }
]

export const unSortableFields = ['info'];

export const unSortabledFieldsForMyRecent = ['first_name', 'last_name', 'user_id', 'info'];