import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import React, { useState } from 'react';
import keycloak from "../../index";
import * as apiConnection from "../../services/apiConnection.js";
import * as httpCalls from "../../services/httpCalls.js";
import { getComparator, SortableTableHead, stableSort } from '../SubmissionList/utils/SortableTableHead';
import SubmissionRow from "../SubmissionList/utils/SubmissionRow";
import { unSortabledFieldsForMyRecent, SubmissionTableHeaderList as headerList, SubmissionTableHeaderListMngCisaAnalyst as headerMngCisaAnalyst } from '../SubmissionList/utils/SubmissionTableHeaderList';
import { rowsPerPageOptions } from '../SubmissionList/utils/submissionListUtils';
import TablePagination from '@material-ui/core/TablePagination';
import { makeStyles } from '@material-ui/core/styles';
import LoadingSubmissions from "../SubmissionList/utils/LoadingSubmissions";

const useStyles = makeStyles({
  root: { width: '100%' },
  container: { maxHeight: 700 }
});

const topPaginationStyle = makeStyles({
  root: { color: 'white' },
  selectIcon: { color: 'white' }
});

/*
 * COMPONENT 
 */
export default function RecentSubmissionTable(props) {

  const classes = useStyles();
  const [rows, setRowData] = useState([]);
  let submitTimes = props.submitTimes;
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState(headerList[0].id);
  const [sortedRows, setSortedRows] = useState([]);
  const [pageChanged, setPageChanged] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [sortStatusClicked, setSortStatusClicked] = useState(false);
  const [loadingData, setLoadingData] = useState(true);

  const topPaginationClass = topPaginationStyle();
  
  let myRoles = keycloak.tokenParsed.realm_access.roles;


  const handleRequestSort = (event, fieldToOrder) => {
    if(order==='desc') {
      setSortStatusClicked(true);
    } else {
      setSortStatusClicked(false);
    }
    
    if (unSortabledFieldsForMyRecent.indexOf(fieldToOrder) !== -1) return;
    const isAsc = orderBy === fieldToOrder && order === 'asc';
    const orderValue = isAsc ? 'desc' : 'asc';
    setOrder(orderValue); // change to opposite order when user clicks
    setOrderBy(fieldToOrder);
    setPage(0);
    setSortedRows(stableSort(rows, getComparator(orderValue, fieldToOrder)));
  }

  const handleChangePage = (event, newPage) => {
      setPage(newPage);
      setPageChanged(true);
  };

  const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
  };

  const getRecentSubmissions = (user_id) => {
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      'Authorization': 'Bearer ' + keycloak.token
    };

  let myRole ="";

  //console.log('My roles=' , myRoles);

  if(myRoles.includes('myPII') ) {
    myRole = ["myPII"];
  } else if(myRoles.includes('MNG Consumer')) {
    myRole = ["MNG Consumer"];
  }
  
    const url = apiConnection.getSubmissionsListUrl();

    let data = {
      "arg_name": "user_id",
      "arg_val": user_id,
      "limit": "100",
      // "user_id": user_id,
      "order_by": "submission_id"  //no PII data return with these http request arguments
    };


    if( myRoles.includes('MNG Consumer') || myRoles.includes('MNG CISA Analyst') || myRoles.includes("myPII") ) {
    
       data = {
        "arg_name": "user_id",
        "arg_val": user_id,
        "limit": "100",
        // "user_id": user_id,
        "order_by": "submission_id",
        "role": myRole   
      }
    }

    data = JSON.stringify(data); //format data to JSON for http request

    //console.log('data to endpt=', data);

    httpCalls
      .configureAxiosRequest(url, header, "PUT", data)
      .then((res) => {
      //console.log("Table Data: " + JSON.stringify(res.data));
        // console.log("Table Data Complete");

        //Sort data DESC on created_ts
        let temp = res.data;
        temp = [].slice.call(temp).sort((a, b) => {
          return (new Date(b[headerList[0].id])).getTime() >= (new Date(a[headerList[0].id])).getTime() ? 1 : -1;
        });
        setRowData(temp);
        setSortedRows(temp);
        setLoadingData(false);
      })
      .catch((err) => {
        console.error("Recent submissions err: " + err);
      });
  }

  React.useEffect(() => {
    setPage(0);
    setRowsPerPage(25);
  }, [rows]);

  React.useEffect(() => {
    //console.log("RecentSubmisionTable Loading table data...");

    let user = {};

    user.user_id = keycloak.tokenParsed.preferred_username;
    user.email = keycloak.tokenParsed.email;
    user.name = keycloak.tokenParsed.name;
    user.first_name = keycloak.tokenParsed.given_name;
    user.last_name = keycloak.tokenParsed.family_name;
    user.organization = keycloak.tokenParsed.organization_name;
    user.phone_number = keycloak.tokenParsed.phone_number;

    getRecentSubmissions(user.user_id);
    // user == null ? console.log("User Profile Error") : console.log("User Profile = ", user) ; 

  }, [submitTimes]);

  const onPageChange = () => {
    setPageChanged(false);
  }

  return (
    loadingData 
      ? <LoadingSubmissions submissionType="my"/> 
      : <div className="my-submission-container">

          <div className="home-header">
            {/* <h4>My Submissions</h4> */}
            <table width="100%">
                    <tbody>
                        <tr>  
                        <td>
                          <h4>My Submissions</h4>
                        </td>                                                                       
                        <td style={{ textAlign: "right" }}>
                            <TablePagination
                                rowsPerPageOptions={rowsPerPageOptions}
                                component="div"
                                count={rows.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                classes={topPaginationClass}
                            />
                        </td>
                        </tr>
                    </tbody>
                </table>

          </div>

          <TableContainer className={classes.container}>
            <Table aria-label="recent submission table" stickyHeader size='small'>
              <SortableTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                headCells={ (myRoles.includes("MNG CISA Analyst") || myRoles.includes("MNG Consumer") || myRoles.includes("myPII")) ? headerMngCisaAnalyst : headerList}
              />
              <TableBody>
                {sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                  <SubmissionRow 
                    sortStatusClicked={sortStatusClicked}
                    key={index} 
                    row={row} 
                    pageChanged={pageChanged} 
                    onPageChange={onPageChange}
                    quickSubmitMade={props.quickSubmitMade}
                    onQuickSubmit={props.onQuickSubmit}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
                
          <TablePagination
              rowsPerPageOptions={rowsPerPageOptions}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
    
  );
}
