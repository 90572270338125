export const getTermsOfServiceIndicator = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/get_terms_of_service_indicator";
}

export const updateTermsOfService = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/update_terms_of_service";
}

export const insertTermsOfService = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/insert_terms_of_service";
}

export const getReportAnalysisUrl = (sid) => {
  return process.env.REACT_APP_REST_API + "/get_analysis_report/?sid=" + sid;
};

export const getEmailReportLocationUrl = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/get_analysis_report_file_location";
}

export const viewReportUrl = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/view_analysis_report";
};

export const viewReportVmrayUrl = (tid) => {
  return process.env.REACT_APP_NEXTGEN_REST + "/vmr/retrieve_report_file/" + tid ;
};

export const getReportLocationUrl = (tid, type) => {
  return (
    process.env.REACT_APP_REST_API +
    "/get_analysis_report_file_location/?tid=" +
    tid +
    "&type=" +
    type
  );
};

export const getUploaderUrl = () => {
  return process.env.REACT_APP_SUB_MS + "/uploader_url";
};

export const getSubmissionUrl = () => {
  return process.env.REACT_APP_SUB_MS + "/uploader";
};

export const submitDroppedFile = () => {
  return process.env.REACT_APP_SUB_MS + "/uploader_drop_file";
}

export const getTLPData = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/get_tlp_data";
}

export const getSubmissionsListUrl = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/submissions";
};

export const getTranMetaListUrl = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/transaction_files";
};

export const createTranData = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/transaction_file";
}

export const getTranTagListUrl = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/tag/get_transaction_file_tag_and_category";
};

export const getSearchCisaUrl = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/search/get_cisa_analyst";
};

export const getSearchMsIsacUrl = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/search/get_ms_isac_analyst";
};

export const getSearchConsumerUrl = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/search/get_consumer";
};

export const getTransactionFile = () => {
  return process.env.REACT_APP_REST_API + "/transaction_file/download/";
};

export const getMifrReport = () => {
  return process.env.REACT_APP_REPORT_GEN_MS + "/generate_report";
};

export const getEmailAnalysisReport = () => {
  return process.env.REACT_APP_GENERATE_EMAIL_REPORT + "/generate_email_report";
}

export const uploadMifrFeedback = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/transaction_file_feedback";
}

export const getMifrFeedback = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/get_transaction_file_feedback";
}

export const buildStixReport = () => {
  return process.env.REACT_APP_STIX_MS + "/generate_stix2_report";
};

export const getExportYaraRules = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/get_yara_system_rule";
}

export const getYaraAdhocRulesForUserUrl = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/yara/yara_adhoc_rules/user_adhoc_rules/";
}

export const getYaraAdhocRuleUrl = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/yara/yara_adhoc_rules";
}

export const getYaraAdhocSharedRuleUrl = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/yara/yara_adhoc_rules/share_rules";
}

export const getYaraAdhocInReviewRuleUrl = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/yara/yara_adhoc_rules/user_adhoc_rules/";
}

export const getAllYaraAdhocInReviewRuleUrl = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/yara/yara_adhoc_rules/all_user_adhoc_rules";
}

export const getYaraCreateUrl = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/yara/yara_adhoc_rules";
};

export const promoteYaraRuleUrl = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/yara/yara_adhoc_rules/promote_rule/";
};

export const getLockedYaraRule = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/yara/yara_adhoc_rules/get_locked_rule";
}
 
export const downloadTidFilesAsZip = () => {
  return process.env.REACT_APP_ZIP_MS + "/download_tid_files";
};

export const getYaraActiveSystemRuleUrl = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/yara/active_system_rules";
}

export const getDisableSystemRuleUrl = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/yara/disable_system_rule";
}

export const getDemoteSystemRuleUrl = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/yara/demote_yara_rule";
}

export const getYaraPromoteDemoteLogs = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/yara/get_yara_logs";
}

export const getYaraLogActionUrl= () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/yara/log_yara_action";
}

export const downloadYaraRuleMatches = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/yara/download_yara_rule_matches";
}

export const getYaraLockUrl = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/yara/yara_adhoc_rules/lock_rule/"
}

export const getYaraUnLockUrl = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/yara/yara_adhoc_rules/unlock_rule/"
}

export const getMarFormDropDownData = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/mar/get_mar_mandatory_data";
}

export const getMarFormTagsData = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/mar/get_tag_data";
}

export const submitMarConfigRequestUrl = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/mar/mar_incident_and_report";
}

export const getMarListDataUrl = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/mar/get_mar_homepage_data";
}

export const updateMarStatusUrl = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/mar/update_status/";
}

export const generateStixMarUrl = () => {
  return process.env.REACT_APP_STIX_MAR + "/stix_mar_generate";
}

export const getMarObjectAndReportUrl = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/get_mar_object_file_and_report_file";
}

export const saveIncidentInfoUrl = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/mar/update_mar_incident/";
}

export const saveReportInfoUrl = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/mar/update_mar_report/";
}

export const getMarObjectList = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/mar/get_list_of_all_object";
}

/* MAR File object */
export const updateMarFile = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/mar/update_mar_object_file/";
}

/* MAR IP Address object */
export const createIPAddress = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/mar/mar_object_ip";
}

export const getIP = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/mar/get_mar_object_ip/";
}

export const updateIP = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/mar/update_mar_object_ip/";
}

export const getListIPDomainObject = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/mar/get_list_of_ip_domain_object";
}

/* MAR Domain object */
export const createDomain = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/mar/mar_object_domain";
}

export const getDomain = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/mar/get_mar_object_domain/";
}

export const updateDomain = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/mar/update_mar_object_domain/";
}

/* MAR Email Message object */
export const createMarEmailMessage = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/mar/mar_object_email_message";
}

export const getMarEmailMessage = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/mar/get_mar_object_email_message/";
}

export const updateMarEmailMessage = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/mar/update_mar_object_email_message/";
}

/* MAR Port object */
export const createPort = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/mar/mar_object_port";
}

export const getPort = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/mar/get_mar_object_port/";
}

export const updatePort = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/mar/update_mar_object_port/";
}

/* MAR URL object */
export const createUrl = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/mar/mar_object_url";
}

export const getUrl = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/mar/get_mar_object_url/";
}

export const updateUrl = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/mar/update_mar_object_url/";
}

/* MAR Http Session object */
export const createHttpSession = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/mar/mar_object_http_session";
}

// export const getHttpSession = () => {
//   return process.env.REACT_APP_NEXTGEN_REST + "/mar/get_mar_object_http_session/";
// }

export const getListOfIPObject = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/mar/get_list_of_ip_object";
}

export const updateHttpSession = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/mar/update_mar_object_http_session/";
}

/* MAR Who Is object */
export const createWhoIs = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/mar/mar_object_whois";
}

export const getWhoIs = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/mar/get_mar_object_whois/";
}

export const updateWhoIs = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/mar/update_mar_object_whois/";
}


/* MAR Screenshot component */
export const marUploadScreenshotFile =() => {
  return process.env.REACT_APP_UPLOAD_MS + "/upload";
}

export const marGetScreenshotFile = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/mar/get_mar_object_file_attachment";
}


/*MAR relationship field */
export const getMarRelationshipList = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/mar/get_mar_object_relationship_cd";
}

export const getMarRelationshipTypes = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/mar/get_mar_object_relationship_cd";
}

export const getTargetObjectList = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/mar/get_target_object";
}

export const setMarRelationship = () => {
  // Input Paramater Example "{'mar_object_relationship_cd': ['This field is required.'], 'source_mar_object_id': ['This field is required.'], 'target_mar_object_id': ['This field is required.']}"    
  return process.env.REACT_APP_NEXTGEN_REST + "/mar/mar_object_relationship";
}

export const getMarObjectRelationships = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/mar/get_list_of_relationships_object";
}

export const deleteMarObjectRelationship = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/mar/delete_mar_object_relationship/";
}

export const getMarFileObjectUrl = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/mar/get_mar_object_file";
}

export const getFileObjectsOfMarReport = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/mar/get_mar_object_file";
}

export const getTagUrl = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/mar/get_tag_data";
}

export const setMarFileObjectUrl = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/mar/set_file_data";
}

// Mar object deletion
export const deleteMarObject = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/mar/delete_all_mar_object/";
}

/* MAR Email Address object */
export const createMarEmailAddress = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/mar/mar_object_email_address";
}

export const getMarEmailAddress = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/mar/get_mar_object_email_address/";
}

export const updateMarEmailAddress = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/mar/update_mar_object_email_address/";
}

// Domain Query (Whois and NsLookup)
export const submitDomainQuery = () => {
  return process.env.REACT_APP_DOMAIN_TOOLS + "/domaintools";
}

// User Feedback Form Submission
export const submitUserFeedbackForm = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/user/mng_feedback";
}

export const addVettedUserToTable = () => {
  return process.env.REACT_APP_NEXTGEN_REST + "/vetted_users";
}

export const getTlpJson = () => {
  return process.env.REACT_APP_FILE_DOWNLOAD_MS + "/download-tlp-json";
}

export const getTlpPdf = () => {
  return process.env.REACT_APP_FILE_DOWNLOAD_MS + "/download-tlp-pdf";
}

export const getVMRayScreenshots = () => {
  return process.env.REACT_APP_SCREENSHOT_STREAMER_MS + "/get-screenshots";
}

export const downloadVMRayScreenshots = () => {
  return process.env.REACT_APP_SCREENSHOT_STREAMER_MS + "/download-screenshots";
}
