// React
import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Collapse from "react-bootstrap/Collapse";
import { useForm } from "react-hook-form";

//Components
import FileUpload from "./FileUpload";
import ErrorDisplay from './ErrorDisplay';
import CharLngthErrorDisplay from './CharLngthErrorDisplay';
import Spinner from "../common/Spinner";

//Services
import * as apiConnection from "../../services/apiConnection.js";
import * as httpCalls from "../../services/httpCalls.js";
import keycloak from "../../index";

// CSS
import "./../../assets/css/submissionform.css";

//Utils
import getFormData from "./utils/getFormDataForSubmission";
import { getInitialFilter, messagePermission } from '../../utils/PermissionUtil.js';
import TLPDropdown from "../tlp/TLPDropdown";

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { TextareaAutosize } from "@material-ui/core";
import FileUploadAndUrl from '../SubmissionForm/FileUploadAndUrl';
import { data } from "jquery";

const submissionFields = [
  {
    controlID: "formFirstName",
    labelField: "First Name",
    name: "first_name",
    placeholder: "First Name",
    maxChar: 30,
    errMsg: "First Name is Required ",
    reqObj: { required: true}, //maxLength: 29 },
  },
   {
    maxChar: 30,
    controlID: "formLasttName",
    labelField: "Last Name",
    name: "last_name",
    placeholder: "Last Name",
    errMsg: "Last Name is Required ",
    reqObj: { required: true} //, maxLength: 29 },
  },
   {
    maxChar: 100,
    controlID: "formOrgName",
    labelField: "Organization",
    name: "organization",
    placeholder: "Organization",
    errMsg: "Organization Name is Required ",
    //  reqObj: { maxLength: 99, required: true },
  },
   {
    maxChar: 50,
    controlID: "formIncidentID",
    labelField: "Incident ID",
    name: "incident_id",
    placeholder: "Enter incident ID",
    errMsg: "Incident ID is Required ",
    //reqObj: { maxLength: 49, required: true },
  },
  {
    controlID: "formPhoneNumber",
    labelField: "Phone Number",
    name: "phone_number",
    placeholder: "Enter phone number",
    errMsg: "Please enter 11 to 15 digits : CountryCode-AreaCode-9999999",
    reqObj: {
    required: true,
     // maxLength: 16,
     // minLength: 11,
      validate: {
        checkValidate: (value) => {

          //console.log('phoneVal= ' , value);

          let regexp = /\d/g; //extract phone number digits

          let phoneNumber = value.match(regexp);

          if(phoneNumber === null || phoneNumber === "") { return false; }

          phoneNumber = phoneNumber.join(""); //remove spaces and convert text to number

          let phoneNumberLength = 0;

          if(phoneNumber !== null){

            phoneNumberLength = phoneNumber.toString().length;
          }

          return ( 10 < phoneNumberLength && phoneNumberLength < 16 );
         
        }
      }
    }
  },
   {
    maxChar: 254,
    controlID: "formEmail",
    labelField: "Email",
    name: "email",
    placeholder: "Email",
    errMsg: "Invalid Email Address",
    reqObj: {
      // required: true,
      maxLength: 253,
      minLength: 1,
      validate: {
        checkValidate: (value) =>
          new RegExp("^$|[a-z0-9._%+-]+@[a-z0-9.-]+[.]{1}[a-z]{2,}$").test(
            value
          ),
      },
    },
  },
];

const disclaimer_input = {
  controlID: "disclaimer_id",
  name: "disclaimer_ind",
  errMsg: "This is a required field.",
  //type: "checkbox",
  //placeholder:"Disclaimer",
  required: "required",
  reqObj: {
    required: true,
    validate: {
      checkValidate: (value) => {  
        let showErr = false;
        //-//if (!value) {
          showErr = true;
       //-//}
      //-//return showErr;        
      }
    }
  }
}

const file_input = {
  name: "files",
  reqObj: {
    required: true,
    validate: {
      checkValidate: (value) => {
        // console.log("Checking file size...");
        let showErr = false;
        let total_bytes = 0;
        for (let i = 0; i < value.length; i++) {
          total_bytes += value[i].size;
          // console.log("File size " + total_bytes + " bytes.");
          if (total_bytes <= 100000000) {
            showErr = true;
          } else {
            showErr = false;
            break;
          }
        }
        return showErr;
      },
    },
  },
  errMsg: "File size cannot exceed 100MB",
};

const validation = (register, paramObjs) => {
  return register(paramObjs);
};

const urlInput = {
  controlID: "urlIputID",
  // labelField: "URL Input ID",
  name: "url_text",
  type: "text",
  placeholder: "URL...",
  required: "required",
  errMsg: "Invalid URL ",
  reqObj: { 
    validate: {
      checkValidate: (value) => {
        let showErr = false;
        //for (let i = 0; i < value.length; i++) {
        //  if(value){
            showErr = true;
        //  }
        //  return showErr;
        //}
      }
    }
  }
}

/**
 * FUNCTIONAL COMPONENT 
 */
const Input = (props) => {
  const { register, errors, handleSubmit } = useForm({ mode: "onChange" });
  const [errorStyle, setInputErrorStyle] = useState({});
  const [inputName, setCurrentInput] = useState({});
  const [isProcessing, setSubmissionStatus] = useState(false);
  const [disclaimer_txt, toggleDisclaimerText] = useState(false);
  const [fieldValue, setFieldValue] = useState({});
  const [displayCharacterLength, setDisplayCharacterLength] = useState(0 + '/500');
  const [characterLength, setCharacterLength] = useState(0);
  const [isEmpty, setEmptyStatus] = useState(true);
  const [electionRelated, setElectionRelated] = useState("");
  const [selectedTLP, setSelectedTLP] = useState("GREEN"); // default tlp state
  const [uploadButtonClicked, setUploadButtonClicked] = useState(true);
  const [urlButtonClicked, setUrlButtonClicked] = useState(false);
  const [urlValue, setUrlValue] = useState("");
  const [isUrlSuccess, setIsUrlSuccess] = useState(null)
  const [urlError, setUrlError] = useState(null)
  const [isFileLoadSuccess, setIsFileLoadSuccess] = useState(null);
  const [submitButtonClicked, setSubmitButtonClicked] =useState(false);
  const [urlDisplayCharacterLength, setDisplayUrlCharacterLength] = useState(0 + '/2048');
  const [urlCharacterLength, setUrlCharacterLength] = useState(0);
  const [disclaimerInd, setDisclaimerInd] = useState(false);
  const [displayCharLength, setDisplayCharLength] = useState(null);
  const [charLength, setCharLength] = useState(0);

  const fileUploadButtonClicked = (e) => {
    setIsFileLoadSuccess(null);
    setIsUrlSuccess(null);
    setUrlError(null);
    setUrlButtonClicked(false);
    setUrlError(false);
    setUrlValue("");
    setUploadButtonClicked(true);
    setSubmitButtonClicked(false);
  }

  const urlBtnClicked = (e) => {
    setIsFileLoadSuccess(null);
    setIsUrlSuccess(null);
    setUrlError(null);
    setUploadButtonClicked(false);
    setUrlValue("");
    setUrlButtonClicked(true);
    setSubmitButtonClicked(false);
  }

  const onSubmit = (data, e) => {
    setSubmitButtonClicked(true);
    setDisplayCharacterLength(0 + '/500');
    setCharacterLength(0);
    setUrlCharacterLength(0);
    setDisplayUrlCharacterLength(props.urlCharacterLength + '/2048');

    // collect form data
    e.preventDefault();
    setSubmissionStatus(true);
    // remove all dashes from phone number to have digits only
    // data['phone_number'] = data['phone_number']
    //   ? data['phone_number'].replace(/-/g, '')
    //   : '';
    // data.countryName = fieldValue.countryName;
    // data.countryCode = fieldValue.countryCode;
    // data.countryDialCode = fieldValue.countryDialCode;
    data.phone_number = fieldValue.phone_number; //need this field with dashes and parenthesis removed
    // data.phoneNumberFormatted = fieldValue.phoneNumberFormatted;
    // data.phoneNumberIsoAlpha2 = fieldValue.phoneNumberIsoAlpha2;
    data.user_id = fieldValue.user_id; //need this field
    data.election_related_ind = electionRelated;  //need this field
    data.traffic_light_protocol_cd = selectedTLP; //need this field
    data.disclaimer_ind = fieldValue.disclaimer_ind; //need this field, the rest of form data is in fieldValue
    // console.log("Data Sent on Submission:   " + JSON.stringify(data));
    setElectionRelated("");
    let header = {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      'Authorization': 'Bearer ' + keycloak.token
    };

    if (urlValue) {
      data.source = "url";
      data.url = urlValue

      // console.log("Data Sent on Submittion:   " + JSON.stringify(data) );
      const apiUrl = apiConnection.getUploaderUrl();
      // console.log("URL:   " + apiUrl);
      const formData = getFormData(data);
      // console.log("Data Sent on Submittion:   " + 
      // JSON.stringify(Object.fromEntries(formData.entries())) );

      httpCalls
        .configureAxiosRequest(apiUrl, header, "POST", formData)
        .then((res) => {
          if (res.data) {
            setIsUrlSuccess(true);
            setUrlError(false);
            setSubmissionStatus(false);
            props.history.push('/submission/confirm');
          } else {
            if (res.errors[0]) {
              setIsUrlSuccess(false);
              setUrlError(true);
            }
          }
        })
        .catch((err) => {
          console.error("Axios Submission : " + e);
          setSubmissionStatus(false);
          props.history.push('/submission/denyUrl');
        });
    } else if (data.files){

      // console.log("Data Sent on Submittion:   " + JSON.stringify(data) );
      const apiUrl = apiConnection.getSubmissionUrl();
      // console.log("URL:   " + apiUrl);
      const formData = getFormData(data);
      // console.log("Data Sent on Submittion:   " + 
      // JSON.stringify(Object.fromEntries(formData.entries())) );

      httpCalls
       .configureAxiosRequest(apiUrl, header, "POST", formData)
       .then((res) => {
          setSubmissionStatus(false);
          props.history.push('/submission/confirm');
        })
        .catch((err) => {
          console.error("Axios Submission : " + e);
          setSubmissionStatus(false);
          props.history.push('/submission/deny');
        });
    }

    if (!urlValue && !data.files) {
      setSubmissionStatus(false);
      props.history.push('/submission');
    }

    resetFormData();
    e.target.reset();
  };

  const handleSelectedTLP = (tlpSelected) => {
    setSelectedTLP(tlpSelected.toLowerCase());
  }

  const resetFormData = () => {
    setFieldValue({});
    // set default tlp of "green"
    setSelectedTLP("GREEN");
  }

  const handleFileChange = (event) => {
    const target_files = event.target.files;
    if (target_files.length < 0) {
      return;
    }
  };

  const handleFieldChange = (e, maxLength) => {    
    const { target } = e;
    const { name, value } = target;
    setCurrentInput(name);
    fieldValue[name] = value;
    setEmptyStatus(value === "");
    setFieldValue({ ...fieldValue }, fieldValue[name]);
    if (name !== 'phone_number') {
      const charLengthLoc = value.length;
      setDisplayCharLength( charLengthLoc + '/' + maxLength);
      if ( charLengthLoc > (maxLength - 1) ) {
        setDisplayCharLength( " maximum limit reached"  ); 
      }
      setCharLength( value.length );
    }
    
  };

  const handleUrl = (e) => {
    const { target } = e;
    const { name, value } = target;
    const urlCharacterLengthLoc = value.length;
    setDisplayUrlCharacterLength(urlCharacterLengthLoc + '/2048');
    if ( urlCharacterLengthLoc > 2047 ) {
      setDisplayUrlCharacterLength( " Maximum limit reached"  );
    }
    setUrlCharacterLength( value.length );
    if ( urlCharacterLengthLoc < 2049 ) {
      setUrlValue(value);
    } else {
      setUrlValue("");
    }
    setUrlValue(value);
  }

  const countCharacters = (e) => {
    const { target } = e;
    const { name, value } = target;
    const characterLengthLoc = value.length;
    setDisplayCharacterLength(characterLengthLoc + '/500');
    if (characterLengthLoc > 499) {
      setDisplayCharacterLength(" maximum limit reached");
    }
    setCharacterLength(value.length);
  };
  const handlePhoneChange = (value, country, e, formattedValue, fieldName) => {
    // console.log(value, country, e, formattedValue);
    setCurrentInput(fieldName);
    fieldValue[fieldName] = value;
    // fieldValue['phoneNumberFormatted'] = formattedValue;
    // fieldValue['countryName'] = country.name;
    // fieldValue['countryCode'] = country.countryCode;
    // fieldValue['countryDialCode'] = country.dialCode;
    // fieldValue['phoneNumberIsoAlpha2'] =  '("' + formattedValue + '","' + country.countryCode + '")';
    setEmptyStatus(value === "");
    setFieldValue({ ...fieldValue }, fieldValue[fieldName]);
    //console.log('fieldValue=' , fieldValue);
  }

  const updateInputBorder = (name, isEmpty) => {
    errorStyle[name] = isEmpty ? ""
      : (errors[name] ? "is-invalid" : "is-valid");
  };


  React.useEffect(() => {
    updateInputBorder(inputName, isEmpty);  
    setInputErrorStyle({ ...errorStyle });
   // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors[inputName], inputName, isEmpty]);

  React.useEffect(() => {
    //console.log("SubmissionForm Calling loadUserProfile...");

    let isSubscribed = true;

    let user = {};

    user.user_id = keycloak.tokenParsed.preferred_username;
    user.email = keycloak.tokenParsed.email;
    user.name = keycloak.tokenParsed.name;
    user.first_name = keycloak.tokenParsed.given_name;
    user.last_name = keycloak.tokenParsed.family_name;
    user.organization = keycloak.tokenParsed.organization_name;
    user.phone_number = keycloak.tokenParsed.phone_number;

    if (isSubscribed) {
      setFieldValue({ ...user });
    }

    //user == null ? console.log("User Profile Error") : console.log("User Profile = ", user); 

    return () => (isSubscribed = false);
  }, []);

  if (getInitialFilter() === "") {
    return (<div><h1 style={{ textAlign: "center" }} >{messagePermission}</h1></div>)
  }
  if (isProcessing) {
    return <Spinner></Spinner>;
  }
  if (!isProcessing) {
    //
    return (
      <div className="submit-page-background">
        <div className="submit-page-container">
          <form
            className="submission-border"

            autoComplete="off"
            onSubmit={handleSubmit(onSubmit)}

          >
          <h1 className="reg_tag" style={{ textAlign: "center" }}>Malware Analysis System</h1> 
          <h5 className="reg_tag" style={{ textAlign: "left" }}>OMB Control No.: 1670-0037; Expiration Date: 10/31/2024</h5>
            <Form.Group className="grouped-inputs ">
              {submissionFields.map((data, index) => {
                return (
                  <Row key={data.controlID}>
                    <Col sm={4} >
                      <Form.Label>{data.labelField}</Form.Label>
                    </Col>
                    <Col sm={8}>  
                      {data.name === 'phone_number' ?
                        <PhoneInput
                          placeholder={data.placeholder}
                          name={data.name}
                          country={'us'}
                          value={fieldValue[data.name] || ""}
                          onChange={(value, country, e, formattedValue) => handlePhoneChange(value, country, e, formattedValue, data.name)}
                          inputProps={{
                            controlid : data.controlID,
                            type: data.type,
                            name: 'phone_number',                            
                            'aria-invalid': errors[data.name] ? "true" : "false",
                            'aria-label': data.labelField,
                            'aria-required': data.required,
                            placeholder: data.placeholder,
                            ref: validation(register, data.reqObj)                           
                           }}
                        />
                        :
                        <Form.Control
                          controlid={data.controlID}
                          className={"group-inputs " + errorStyle[data.name]}
                          type={data.type}
                          name={data.name}
                          defaultValue={fieldValue[data.name] || ""}
                          // value={fieldValue[data.name]}
                          onChange={(e) => handleFieldChange(e, data.maxChar)}     
                          aria-invalid={errors[data.name] ? "true" : "false"}
                          aria-label={data.labelField}
                          aria-required={data.required}
                          maxLength={data.maxChar}
                          placeholder={data.placeholder}
                          ref={validation(register, data.reqObj)}
                        />
                      }
                      <Form.Text> 
                        {inputName === data.name && data.name !== 'phone_number' ?
                        <div style={{ color: charLength < data.maxChar ? 
                          (charLength === 0 ? "gray" : "green") : "red" }}
                        >
                         {displayCharLength}
                        </div>
                        :
                        <CharLngthErrorDisplay field={data} errors={errors} />}
                      </Form.Text>
                    </Col>
                  </Row>
                );
              })}
            </Form.Group>

            <Form.Group className="grouped-inputs" controlId="formFileUpload">
              <Row>
                <br></br>
                <Col sm={4}>
                <FileUploadAndUrl
                    fileUploadButtonClicked={(e) => fileUploadButtonClicked(e)}
                    urlBtnClicked={(e) =>  urlBtnClicked(e)}
                />
                </Col>
                <br></br>
                <Col sm={8}>
                  {uploadButtonClicked &&  
                    <FileUpload
                      handleFileChange={(e) => handleFileChange(e)}
                      refs={validation(register, file_input.reqObj)}
                      name={file_input.name}
                      ariaInvalid={errors[file_input.name] ? "true" : "false"} 
                    />
                  }
                  <Form.Text>
                    <ErrorDisplay field={file_input} errors={errors} />
                  </Form.Text>
                  {urlButtonClicked && 
          <table width="100%">
          <tbody>
            <tr>  
              <span style={{float: "right", color: urlCharacterLength<2048 ? 
                (urlCharacterLength==0 ? "gray" : "green") : "red"}}>
                {urlDisplayCharacterLength}
              </span>
            </tr>
            <tr>
              <TextareaAutosize
                controlid="formUrl"
                name={props.name}
                className="autosize-textarea"
                defaultValue={""}
                maxLength="2048"
                onChange={handleUrl}
                placeholder={"url..."}
                style={{padding: '5px', fontSize: '1rem', height: "35.55px"}}
                aria-label="URL"
                aria-invalid={props.ariaInvalid}
                aria-required="true"
                ref={props.refs}
                multiple={false}
              >
              </TextareaAutosize>
            </tr>
          </tbody>
        </table>
                }
                  {/* !urlValue && !uploadButtonClicked && !submitButtonClicked &&
                    <div
                    >
                      No URL entred.
                    </div>
                  {!urlValue && !uploadButtonClicked && submitButtonClicked &&
                    <div style={{color: 'red',  }} >
                      No URL entred.
                    </div>
                  }
                  */}
                  { urlButtonClicked &&
                    <div className="quick-submit-file-condition">
                      URL must start with http:// or https://
                    </div>
                  }
                </Col>
              </Row>
            </Form.Group>

            <Form.Group className="grouped-inputs " controlId="formDisclaimer">
              <Row style={{marginTop: "7px"}}>
                <br></br>
                <Col sm={4}>
                  <Form.Label>Election Related?</Form.Label>
                </Col>
                <br></br>
                <Col sm={8}>
                  <input
                    aria-label="election related aria-label for submission form"
                    name={'election related name for submission form'}
                    // className="form-checks"
                    // ref={validation(register, disclaimer_input.reqObj)}
                    onChange={(e) => setElectionRelated(e.target.checked ? 'T' : "")}
                    // checked={election_trlated}
                    type="checkbox"
                    id="gridCheck"
                  />
                </Col>
              </Row>
            </Form.Group>

            <Form.Group className="grouped-inputs " controlId="formDisclaimer">
              <Row>
                <br></br>
                <Col sm={4}>
                  <Form.Label>Traffic Light Protocol</Form.Label>
                </Col>
                <br></br>
                <Col sm={8}>
                  <TLPDropdown 
                    selectedTLP={handleSelectedTLP}
                  />
                </Col>
              </Row>
            </Form.Group>

            <Form.Group className="grouped-inputs " controlId="formFeedback">
              <div className="textarea-container"
                style={{ color: characterLength < 500 ? (characterLength === 0 ? "gray" : "green") : "red" }}
              >Comments {displayCharacterLength}
                <TextareaAutosize
                  aria-label="comments"
                  aria-required="false"
                  className="autosize-textarea"
                  name="description"
                  maxLength="500"
                  onChange={(e) => countCharacters(e, 500)}
                  minRows="4"
                  ref={validation(register)}
                  placeholder="Comments"
                  style={{ fontSize: '1rem' }}
                />
              </div>
              
            <div className="">
              <table>
                <tbody>
                  <tr>
                    <td width={"7%"}>
                      <input
                        controlid={disclaimer_input.controlID}
                        aria-label={"disclaimer checkbox"}
                        name={disclaimer_input.name}
                        aria-invalid={errors[disclaimer_input.name] ? "true" : "false"} 
                        className="form-checks"
                        ref={validation(register, disclaimer_input.reqObj)}
                        onChange={() => setDisclaimerInd(!disclaimerInd)}
                        checked={disclaimerInd}
                        value={disclaimerInd}
                        type={"checkbox"}
                        id={"gridCheck"}
                      />
                    </td>
                    <td width={"93%"}>
                      <span id="agree-box">
                        By checking this box, you agree to Malware Next Gen <a className="agree-to-link" href="#termservice-text">
                        Terms of Service</a>, <a className="agree-to-link" href="#disclaimer-text">Web Disclaimer</a>, 
                        the <a className="agree-to-link" href="#privacy-act-text">Privacy Act Statement</a>,
                        and the <a className="agree-to-link" target="_blank" href="https://www.dhs.gov/privacy-policy">Privacy Policy</a>.
                      </span>
                      </td>
                  </tr>
                </tbody>
              </table>
              <Button
                variant="link"
                style={{ marginLeft: 2, marginBottom: 2, padding: 1 }}
                onClick={() => toggleDisclaimerText(!disclaimer_txt)}
                aria-controls="example-collapse-text"
                aria-expanded={disclaimer_txt}
              >
              </Button>
              <div id="termservice-text" className="overlay">
                <div className="popup">
                  <h2>Terms of Service</h2>
                  <a className="close_butn" style={{color: "black"}} href="#agree-box">X</a>
                  <div className="content">
                    <p>.
                      By submitting malware artifacts to the Department of Homeland Security's (DHS) United States Computer Emergency Readiness Team (US-CERT), 
                      submitter agrees to the following: Submitter requests that DHS provide analysis and warnings of threats to and vulnerabilities of its systems, 
                      as well as mitigation strategies as appropriate. Submitter has obtained the data, including any electronic communications, 
                      and is disclosing it to DHS consistent with all applicable laws and regulations. 
                      Submitter acknowledges that DHS's analysis is for the purpose of identifying a limited range of threats and vulnerabilities. 
                      Submitter understands that DHS makes no warranty that information provided by DHS will detect or mitigate any particular threat or vulnerability. 
                      Submitter agrees that the U.S. Government, its officers, contractors, 
                      and employees are not liable or otherwise responsible for any damage resulting from the implementation of any guidance provided. 
                      Submitter understands that DHS may retain data submitted to it and use it, alone or in combination with other data, 
                      to increase its situational awareness and understanding of cybersecurity threats; 
                      that DHS may share data submitted to it with other cybersecurity centers in the US Government; 
                      that DHS may, from time to time, derive from submitted data certain indicators of malicious activity related to cybersecurity, 
                      including but not limited to Internet Protocol (IP) addresses, domain names, file names and hash/digest values; 
                      and that DHS may issue a warnings to the public about the malicious nature of such indicators, in a way that is not attributable to submitter.
                    </p>
                  </div>
                </div>
              </div>
              <div id="privacy-act-text" className="overlay">
                  <div className="popup">
                      <h2>Privacy Act Statement</h2>
                      <a className="close_butn" style={{color: "black"}} href="#agree-box">X</a>
                      <div className="content">
                          <p>
                              <span className="privacy-item">Authority:</span>
                              <span className="privacy"> 5 U.S.C. � 301 and 44 U.S.C � 3101 authorize the collection of this information.</span>
                          </p>
                          <br></br>
                          <p>
                              <span className="privacy-item">Purpose:</span>
                              <span className="privacy"> The primary purpose for the collection of this information is to allow the Department of Homeland Security to contact you regarding your request.</span>
                          </p>
                          <br></br>
                          <p>
                              <span className="privacy-item">Routine Uses:</span>
                              <span className="privacy"> The information collected may be disclosed as generally permitted under 5 U.S.C. � 552a(b) of the Privacy Act of 1974, as amended. This includes using the information as necessary and authorized by the routine uses published in DHS/ALL-002 - Department of Homeland Security (DHS) Mailing and Other Lists System November 25, 2008, 73 FR 71659.</span>
                          </p>
                          <br></br>
                          <p>
                              <span className="privacy-item">Disclosure:</span>
                              <span className="privacy"> Providing this information is voluntary, however, failure to provide this information will prevent DHS from contacting you in the event there are questions regarding your request.</span>
                          </p>
                      </div>
                  </div>
              </div>
              <div id="disclaimer-text" className="overlay">
                  <div className="popup">
                      <h2>Web Disclaimer</h2>
                      <a className="close_butn" style={{color: "black"}} href="#agree-box">X</a>
                      <div className="content">
                        <p className="disclaimer">By submitting malware artifacts to the Department of Homeland
                            Security's (DHS) Cybersecurity &amp; Infrastructure Security Agency (CISA), submitter
                            agrees to the following:
                        </p>
                        <br></br>
                        <p className="disclaimer">Submitter requests that DHS provide analysis and warnings of
                            threats to and vulnerabilities of its systems, as well as mitigation strategies as
                            appropriate.
                        </p>
                        <br></br>
                        <p className="disclaimer">Submitter has obtained the data, including any electronic
                            communications, and is disclosing it to DHS consistent with all applicable laws and
                            regulations.
                        </p>
                        <br></br>
                        <p className="disclaimer">Submitter acknowledges that DHS's analysis is for the purpose of
                            identifying a limited range of threats and vulnerabilities. Submitter understands that
                            DHS makes no warranty that information provided by DHS will detect or mitigate any
                            particular threat or vulnerability.
                        </p>
                        <br></br>
                        <p className="disclaimer">Submitter agrees that the U.S. Government, its officers,
                            contractors, and employees are not liable or otherwise responsible for any damage
                            resulting from the implementation of any guidance provided.
                        </p>
                        <br></br>
                        <p className="disclaimer">Submitter understands that DHS may retain data submitted to it
                            and use it, alone or in combination with other data, to increase its situational
                            awareness and understanding of cybersecurity threats; that DHS may share data submitted
                            to it with other cybersecurity centers in the US Government; that DHS may, from time to
                            time, derive from submitted data certain indicators of malicious activity related to
                            cybersecurity, including but not limited to Internet Protocol (IP) addresses, domain
                            names, file names and hash/digest values; and that DHS may issue warnings to the public
                            about the malicious nature of such indicators, in a way that is not attributable to
                            submitter.
                        </p>
                      </div>
                  </div>
              </div>
            </div>
            </Form.Group>

            <Form.Group className="grouped-inputs " controlId="formDisclaimer">
            <Form.Text>
                  <ErrorDisplay field={disclaimer_input} errors={errors} />
                </Form.Text>
            </Form.Group>

            <Form.Group>
              <div className="center">
                <Button
                  disabled={!urlValue && urlButtonClicked}
                  aria-label="submit"
                  aria-required="true"
                  type="submit"
                  size="lg"
                  className="submission-btn"
                >
                  Submit
                </Button>
              </div>
            </Form.Group>
          </form>
        </div>      
      </div>
    );
  }
};

export default Input;
