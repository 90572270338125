import React from 'react';
import {Icon, Menu} from "semantic-ui-react";
import '../../../../assets/css/Sidebar.scss';
import CISA_LOGO from "../../../../assets/img/logo/cisa_logo.png";

/**
  Side Bar component for Email Analysis 
**/
const EmailAnalysisSideBar = () => {

  const fixScroll = (sectionId) => {
    const id = sectionId;
    const yOffset = 166;
    const element = document.getElementById(id);
    const y = element.getBoundingClientRect().top + window.pageYOffset - yOffset;
    window.scrollTo({top: y, behavior: "smooth"});
  }

  return (
    <div>
      <Menu className='side-nav' style={{margin: "auto"}} borderless vertical>
        <span title="Back to top" className="back-to-top-icon" onClick={()=>window.scrollTo(0, 0)}>
          <Icon size='large' name='caret square up outline'/>
        </span> 
        <div className="container">
          <img src={CISA_LOGO}/>
        </div>
        <div style={{width: "65%", margin: "auto"}}>
          <Menu.Item className={['sidebar-item', 'highlight-item'].join(' ')}>
            <div className='sidebar-item-alignment-container'>
              <span><Icon size='large' name='file alternate outline'/></span> 
              <span>
                <a className="mifr-sidebar-item" onClick={()=>fixScroll("Overview")}>Overview</a>
              </span>  
            </div>
          </Menu.Item>
          <Menu.Item className={['sidebar-item', 'highlight-item'].join(' ')}>
            <div className='sidebar-item-alignment-container'>
              <span><Icon size='large' name='info circle'/></span> 
              <span>
                <a className="mifr-sidebar-item" onClick={()=>fixScroll("Headers")}>Headers</a>
              </span> 
           </div>
          </Menu.Item>
          <Menu.Item className={['sidebar-item', 'highlight-item'].join(' ')}>
             <div className='sidebar-item-alignment-container'>
              <span><Icon size='large' name='train'/></span> 
             <span>
               <a className="mifr-sidebar-item" onClick={()=>fixScroll("Transit")}>Transit</a>
              </span>      
             </div>
          </Menu.Item>
          <Menu.Item className={['sidebar-item', 'highlight-item'].join(' ')}>
             <div className='sidebar-item-alignment-container'>
               <span><Icon size='large' name='point'/></span> 
               <span>
                 <a className="mifr-sidebar-item" onClick={()=>fixScroll("IPs")}>IPs</a>
               </span>
             </div>
          </Menu.Item>
          <Menu.Item className={['sidebar-item', 'highlight-item'].join(' ')}>
             <div className='sidebar-item-alignment-container'>
               <span><Icon size='large' name='at'/></span> 
               <span>
                 <a className="mifr-sidebar-item" onClick={()=>fixScroll("Email Addresses")}>Email Addresses</a>
               </span>
             </div>
          </Menu.Item>
          <Menu.Item className={['sidebar-item', 'highlight-item'].join(' ')}>
             <div className='sidebar-item-alignment-container'>
               <span><Icon size='large' name='book'/></span> 
               <span>
                 <a className="mifr-sidebar-item" onClick={()=>fixScroll("Body")}>Body</a>
               </span>
             </div>
          </Menu.Item>
          <Menu.Item className={['sidebar-item', 'highlight-item'].join(' ')}>
             <div className='sidebar-item-alignment-container'>
               <span><Icon size='large' name='attach'/></span> 
               <span>
                 <a className="mifr-sidebar-item" onClick={()=>fixScroll("Attachments")}>Attachments</a>
               </span>
             </div>
          </Menu.Item>
        </div>
      </Menu>
    </div>
  );
}

export default EmailAnalysisSideBar;